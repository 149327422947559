import React from 'react';
import styled from 'styled-components';
import { colors } from '../../variables/Colors';

const RightPanel = ({ certificate }) => {

    console.log('certificate', certificate)

    const rendering = () => {
        return (
            <RightPanelSC>
                <img src={`${certificate.imageUrl}`}/>
            </RightPanelSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const RightPanelSC = styled.div`
    background-color : #F5F5F5;
    flex-grow : 1;
    min-height : 100%;
    overflow: hidden;
    display : flex;
    justify-content : center;
    align-items : center;


    &>img {
        max-width : 330px;
        max-height : 330px;

    }
`

export default RightPanel