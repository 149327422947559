import React from 'react';
import styled from 'styled-components';
import BasicInfo from './basicInfo/BasicInfo';
// import TrainingMailSchedule from './TrainingMailSchedule';
// import TrainingFeedBackForm from './feedbackForm/TrainingFeedbackForm';
// import TrainingCertificates from '../certificates/TrainingCertificates';
// import Review from './review/Review';
import { AddOrEditTrainingContext } from '../AddOrEditTraining';
import TrainingMailSchedule from './TrainingMailSchedule';
import { defaultTouchPointsArray } from '../../addTraining/defaultTouchPointsArray';
import TrainingFeedBackForm from '../mainContent/feedbackForm/TrainingFeedbackForm';
import TrainingCertificates from '../mainContent/certificates/TrainingCertificates';
import Review from '../mainContent/review/Review';
import TrainingProperties from './trainingProperties/TrainingProperties';

const MainContent = ({ 
    register, 
    errors,
    surveys,
    mailTemplates,
    state,
    setState,
    formValues,
    trigger,
    imageURL,
    setImageURL,
    file,
    setFile,
    activeItem,
    trainingToEdit,
    trainingToEditLocal,
    setTrainingToEditLocal,
    certificateTemplates,
    locations,
    editor
}) => {

    const rendering = () => {
        return (
            <MainContentSC>
                <BasicInfo 
                    visible={activeItem === 0}
                    register={register} 
                    errors={errors}
                    trigger={trigger}    
                    imageURL={imageURL}
                    setImageURL={setImageURL}
                    file={file}
                    setFile={setFile}
                    trainingToEdit={trainingToEdit}
                />
 
                <TrainingProperties
                    visible={activeItem === 1}
                    locations={locations}
                    state={state}
                    setState={setState}
                    register={register} 
                    errors={errors}
                    trainingToEditLocal={trainingToEditLocal}
                    setTrainingToEditLocal={setTrainingToEditLocal}
                    editor={editor}
                />

                <TrainingMailSchedule
                    visible={activeItem === 2}
                    register={register} 
                    errors={errors}
                    state={state}
                    setState={setState}
                    mailTemplates={mailTemplates}
                    defaultTouchPoints={defaultTouchPointsArray}
                    trainingToEditLocal={trainingToEditLocal}
                    setTrainingToEditLocal={setTrainingToEditLocal}
                />

                <TrainingFeedBackForm
                    visible={activeItem === 3}
                    surveys={surveys}
                    state={state}
                    setState={setState}
                    trainingToEditLocal={trainingToEditLocal}
                    setTrainingToEditLocal={setTrainingToEditLocal}
                />

                <TrainingCertificates
                    visible={activeItem === 4}
                    state={state}
                    setState={setState}
                    trainingToEditLocal={trainingToEditLocal}
                    setTrainingToEditLocal={setTrainingToEditLocal}
                    certificateTemplates={certificateTemplates}
                />
                   
                <Review
                    visible={activeItem === 5}
                    state={state}
                    setState={setState}
                    formValues={formValues}
                    trigger={trigger}
                    errors={errors}
                    activeItem={activeItem}
                    imageURL={imageURL}
                    trainingToEditLocal={trainingToEditLocal}

                />
                </MainContentSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const MainContentSC = styled.div`
    display : flex;
    flex-direction : column;
    flex-grow : 1;
    max-height : 100%;
    overflow-y : scroll;
    border-bottom : 1px solid #ccc;

`

export default MainContent