import { CheckOutlined } from '@mui/icons-material';
import { Checkbox } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { IndividualTrainingContext } from '../IndividualTraining';

const Attendee = ({ attendee }) => {

    const { state, setState } = React.useContext(IndividualTrainingContext);

    const rendering = () => {

        const isSelected = state.selectedForSurvey.find(selectedAttendee => selectedAttendee._id === attendee._id);
        
        const clickFunction = () => {
            if(!!isSelected) {
                const newState = state.selectedForSurvey.filter(selectedAttendee => selectedAttendee._id !== attendee._id);
                setState({...state, selectedForSurvey : newState});
            } else {
                const newState = [...state.selectedForSurvey, attendee];
                setState({...state, selectedForSurvey : newState});
            }
        }
        
        return (

            <AttendeeSC>
                <div className='left'>
                    <Checkbox 
                        checked={!!isSelected}
                        onClick={() => clickFunction()}
                    />
                    <p>{`${attendee.firstName} ${attendee.lastName}`}</p>
                </div>
                <div className='right'>
                    {state.sendSurveyLoading ? 
                        <p>loading...</p> : 
                            attendee.receivedSurvey ?
                                <p className='status-button received'>received survey</p>
                                :
                                <p className='status-button not-sent'>surey not sent</p>
                        }
                </div>
            </AttendeeSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const AttendeeSC = styled.div`
    border-bottom : 1px solid #e0e0e0;
    display : flex;
    align-items : center;
    justify-content : space-between;

    .left {
        display : flex;
        align-items : center;
        padding : 15px;
        &>p {
            padding-left : 20px;
        }

    }

    .right {
        display : flex;
        align-items : center;
        margin-right : 15px;
    }

    .status-button {
        padding : 5px 10px;
        border-radius : 5px;
    }

    .received {
        background-color : #4CAF50;
        color : white;
    }

    .not-sent {
        background-color : lightgrey;
        color : white;
    }
`

export default Attendee