import React from 'react';
import styled from 'styled-components';
import { backEndAdress } from '../../variables/back-endAdress';
import Axios from 'axios'; 
import Modal from '../GeneralComponents/Modal';
import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';

const IndividualCertificate = () => {

    const [state, setState] = React.useState({
        certificate : null
    });

    const certificateIdFromUrl = window.location.hash.split('/')[3];

    React.useEffect(() => {
        const getCertificate = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/certificates/${certificateIdFromUrl}`)
                setState({
                    ...state,
                    certificate : response.data
                })
            } catch (error) {
                console.log(error)
            }
        }

        getCertificate()
    },[]);

    const rendering = () => {
        if(!state.certificate) return <p>Loading...</p>
        return (
            <Modal>
                <IndividualCertificateSC>
                    <LeftPanel certificate={state.certificate}/>
                    <RightPanel certificate={state.certificate}/>
                </IndividualCertificateSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const IndividualCertificateSC = styled.div`
    min-width : 600px;
    min-height : 400px;
    display : flex;
    background-color : white;
    box-shadow : 0px 0px 10px 0px rgba(0,0,0,0.1);
    border-radius : 5px;
    overflow : hidden;
`

export default IndividualCertificate