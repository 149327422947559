import React from 'react';
import styled from 'styled-components';
import { colors } from '../../variables/Colors';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import EmailIcon from '@mui/icons-material/Email';
import { HeigtsPaddingBorderRadius } from '../../variables/HeigtsPaddingBorderRadius';
import { useNavigate } from 'react-router-dom';
import GroupIcon from '@mui/icons-material/Group';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import SchoolIcon from '@mui/icons-material/School';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';

const { headerHeight } = HeigtsPaddingBorderRadius;

const Sidebar = ({ appState , setAppState }) => {

    const [activeIndex, setActiveIndex] = React.useState(0);

    const navigate = useNavigate();
    const currentlyActive = window.location.hash.split('/')[1];

    const items = [{
        name : 'Trainings',
        icon : <DashboardIcon />,
        path : '/'
    },{
        name : 'Metrics',
        icon : <SsidChartIcon />,
        path : '/metrics'
    },{
        name : 'Trainers',
        icon : <GroupIcon />,
        path : '/trainers'
    
    },{
        name : 'Locations',
        icon : <LocationCityIcon />,
        path : '/locations'
    },{
        name : 'Mail editor',
        icon : <ColorLensIcon />,
        path : '/mail-editor'
    },{
        name : 'Certificates',
        icon : <SchoolIcon />,
        path : '/certificates/overview',
    },{
        name : 'Users',
        icon : <ManageAccountsIcon />,
        path : '/user-management',
    },
    // {
    //     name : 'Mail traffic',
    //     icon : <MarkEmailUnreadIcon />,
    //     path : '/mail-traffic',
    // }
    ]

    const rendering = () => {


        const clickItem = (index, item) => {
            setActiveIndex(index);
            navigate(item.path)
        }

        return (
            <SidebarSC style={{ minWidth : appState.sideBarExpanded ? '220px':'50px', maxWidth : appState.sideBarExpanded ? '220px':'50px'}}>
                <div className='title-item' onClick={() => setAppState({...appState, sideBarExpanded : !appState.sideBarExpanded})}
>
                    {appState.sideBarExpanded ? 

                        <>
                            <p className='menu-title'>Menu</p>
                            <KeyboardDoubleArrowLeftIcon 
                            />
                        </>
                        :
                        <ArrowRightIcon />

                    }
                </div>

                {items.map((item, index) => {

                    const currentIndex = items.findIndex((item) => item.path.split('/')[1] === currentlyActive);
                    const selected = currentIndex === index ? 'selected' : '';

                    return (
                        <div 
                            key={index} 
                            className={`menu-item ${selected}`}
                            onClick={() => clickItem(index, item)}    
                        >
                            <div className={`main ${selected}`}>
                                {item.icon}
                                <p>{item.name}</p>
                            </div>

                            {item.subItems && appState.sideBarExpanded &&
                                <div className='sub-menu'>
                                    {item.subItems.map((subItem, index) => {
                                        return (
                                            <p 
                                                key={index}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    navigate(subItem.path)
                                                }}
                                                className='sub-item'
                                            >{subItem.name}</p>
                                        )
                                    })}
                                </div>
                            }
                        </div>
                    )
                }
                )}
            </SidebarSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SidebarSC = styled.div`
    background-color : white;
    min-width : 220px;
    max-width : 220px;
    display : flex;
    flex-direction : column;
    box-sizing : border-box;
    transition : 0.2s;
    overflow : hidden;
    margin-top : 10px;
    border-radius : 0 10px 0 0;
    color : ${colors.darkGrey};

    .title-item {
        display : flex;
        justify-content : space-between;
        align-items : center;
        box-sizing : border-box;
        padding : 20px;
    }

    .menu-title {
        font-weight : 500;
        font-size : 18px;
    }

    .menu-item {

        &:hover {
            cursor : pointer; 
        }

        .main {
            display : flex;
            align-items : center;
            box-sizing : border-box;

            >p {
                font-size : 17px;
            }

            &>svg {
                min-width : 50px;
                max-width : 50px;
                min-height : 50px;;
                max-height : 50px;;
                padding : 17px;
                box-sizing : border-box;
                color : grey;
                padding-right : 0;
                padding-left : 0;
                
                &:hover {
                    cursor : pointer;
                }
            }
        }

        .selected { 

            &>svg {
                color : ${colors.darkGrey};
            }
        }

        .sub-menu {
            display : flex;
            flex-direction : column;
            margin-top : -10px;
            padding-left : 40px;

            
            &>p {
                box-sizing : border-box;
                font-size : 14px;
                color : ${colors.midGrey};
                transition : 0.2s;
                padding-top : 5px;
                padding-bottom : 5px;
                padding-left:  10px;

                &:hover {
                    cursor : pointer;
                }

            }
        }


    }

    .selected {
        font-weight : 600;
    }

    &>div {
        box-sizing : border-box;


        &>p {
            font-weight : 0;
            font-size : 14px;
        }
    }

`

export default Sidebar