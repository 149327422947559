import React from 'react';
import Header from './header/Header';
import MainContent from './mainContent/MainContent';
// import Footer from './footer/Footer';
import Modal from '../GeneralComponents/Modal';
import styled from 'styled-components';
import WebIcon from '@mui/icons-material/Web';
import { set, useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import TextFormInput from '../GeneralComponents/formInputs/TextFormInput';
import { AppContext } from '../../App';
import Axios from 'axios'; 
import { backEndAdress } from '../../variables/back-endAdress'; 
import { defaultTouchPointsArray } from '../addTraining/defaultTouchPointsArray';
import OnCompleteAddOrEditTraining from './OnCompleteAddOrEditTraining'
import Footer from '../addOrEditTrainingComponent/footer/Footer';
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import TextStyle from '@tiptap/extension-text-style'
import Color from '@tiptap/extension-color'

export const AddOrEditTrainingContext = React.createContext(null);

const AddOrEditTraining = ({ 
    trainingToEdit, 
    appState, 
    closeWindowFunction, 
    openSavedTraining, 
    setTraining, 
    setMailJobs,
    state: editTrainingState,
    setState : setEditTrainingState
}) => {

    const { tenant } = appState;
    const form = useForm();
    const { register, handleSubmit, watch, trigger, control, formState } = form;
    const { errors, isValidating } = formState;
    const formValues = watch(); 

    const [activeItem, setActiveItem] = React.useState(0);
    const [surveys, setSurveys] = React.useState([]);
    const [certificateTemplates, setCertificateTemplates] = React.useState([]);
    const [mailTemplates, setMailTemplates] = React.useState([]);
    const [locations, setLocations] = React.useState([]);
    const [imageURL, setImageURL] = React.useState(null);
    const [file, setFile] = React.useState(null);
    const [touchPointsForTraining, setTouchPointsForTraining] = React.useState([defaultTouchPointsArray]);

    const formRef = React.useRef(null);

    const [state, setState] = React.useState({
        selectedCertificateTemplate : null,
        certificateTemplates : [],
        selectedSurvey : null,
        touchPoints : [],
        completeTrainingMenu : false,
        savedTrainingResponse : null,
        touchPointsFromChild: [],
        trainingCreated : '',
        availableLocations : [],
    });

    const content = trainingToEdit ? trainingToEdit.htmlForWebsite : '<p>Hello! This is a basic editor with Tiptap.</p>';

    const editor = useEditor({
        extensions: [
            StarterKit,
            TextStyle
        ],
        content: content,
    })

    const [trainingToEditLocal, setTrainingToEditLocal] = React.useState(null);

    React.useEffect(() => {
        setTrainingToEditLocal(trainingToEdit);
        setImageURL(trainingToEdit?.imageUrl);

    },[trainingToEdit]);

    const navigationItems = [{
        title: 'Training',
        icon : <WebIcon />
    },{
        title: 'Properties',
        icon : <WebIcon />
    },{
        title : 'Mailschedule',
        icon : <WebIcon />
    },{
        title : 'Feedback',
        icon : <WebIcon />
    },{
        title : 'Certificate',
        icon : <WebIcon />
    },{
        title : 'Review',
        icon : <WebIcon />
    }];

    // Fetching data on component render 
    React.useLayoutEffect(() => {

        const getEmailTemplates = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/mail-templates/find-all/${tenant}`);
                setMailTemplates(response.data);
            } catch (error) {
                console.log(error);
            }
        }
        
        const getCertificateTemplates = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/certificate-template/get-all/${tenant}`);
                setCertificateTemplates(response.data);
            } catch (error) {
                console.log(error);
            }
        }

        const getSurveys = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/surveys`);
                setSurveys(response.data);
            } catch (error) {
                console.log(error);
            }
        }

        const getLocations = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/locations/all-locations/${tenant}`);
                setLocations(response.data);
            } catch (error) {
                console.log(error);
            }
        }

        getCertificateTemplates();
        getEmailTemplates();
        getSurveys();
        getLocations();

    },[]);

    React.useLayoutEffect(() => {
        if(trainingToEdit) {

            const { surveyId, certificateTemplateId } = trainingToEdit;
            const selectedSurvey = surveys.find(survey => survey._id === surveyId);
            const selectedCertificateTemplate = certificateTemplates.find(certificate => certificate._id === certificateTemplateId);

            setState({
                ...state,
                touchPointsFromChild : trainingToEdit.defaultTouchPointsArray,
                selectedSurvey,
                selectedCertificateTemplate
            });
        }
    },[trainingToEdit, surveys]);

    // Save or edit training
    const onSubmit = (data) => {

        setState({...state, trainingCreated : data.title});
        
        const html = editor.getHTML()

        const training = {
            ...trainingToEditLocal,
            name : data.title,
            description : data.description,
            certificateTemplateId : state.selectedCertificateTemplate._id,
            surveyId : state.selectedSurvey._id,
            defaultTouchPointsArray : trainingToEditLocal.defaultTouchPointsArray,
            tenant,
            hasThumbnailUrl : !!imageURL,
            htmlForWebsite : html,
            pricePerDay : data.price,
            numberOfDays : data.numberOfDays
        };
     
        const formData = new FormData();
        formData.append('s3Image', file);
        formData.append('training', JSON.stringify(training));

        // const saveTraining = async () => {
        //     try {
        //         const response = await Axios.post(`${backEndAdress}/api/trainings`, formData , { headers : { 'Content-Type' : 'multipart/form-data' } });
                
        //         setState({
        //             ...state, 
        //             completeTrainingMenu : !trainingToEditLocal._id ? true : false,
        //             savedTrainingResponse : !trainingToEditLocal._id ? response.data : null,
        //             preOpenTraing : response.data              
        //         });
                
        //         setTrainingToEditLocal(response.data.training);

        //         trainingToEditLocal._id && closeWindowFunction();

        //     } catch (error) {
        //         console.log('error', error);
        //     }
        // }

        const saveTraining = async () => {
            try {
                const response = await Axios.post(`${backEndAdress}/api/trainings`, formData , { headers : { 'Content-Type' : 'multipart/form-data' } });

                setEditTrainingState({
                    ...editTrainingState, 
                    preOpenTraing : response.data,
                    trainingToEdit : null,
                    numForFetching : Math.random()
                });

                setState({
                    ...state,
                    completeTrainingMenu : !trainingToEditLocal._id ? true : false,
                })
                
                // setTrainingToEditLocal(response.data.training);

                // trainingToEditLocal._id && closeWindowFunction();

            } catch (error) {
                console.log('error', error);
            }
        }

        saveTraining();

    }


    // Receive the training as a proper and then use layout effect to render it to a local state whithin this component
    return ( trainingToEditLocal &&
        <Modal>
            <AddOrEditTrainingSC>
                <AddOrEditTrainingContext.Provider value={{ 
                    navigationItems,
                    activeItem,
                    setActiveItem
                }}>
                    
                    { state.completeTrainingMenu && 
                        <OnCompleteAddOrEditTraining 
                            trainingCreated={state.trainingCreated}
                            savedTrainingResponse={state.savedTrainingResponse}    
                            state={state}
                            setState={setState}
                            openSavedTraining={openSavedTraining}
                        /> 
                    }
                    <>
                        <Header 
                            closeWindowFunction={closeWindowFunction}
                        />
                        <form 
                            onSubmit={handleSubmit(onSubmit)} 
                            noValidate
                            ref={formRef} 
                        >
                            <MainContent 
                                register={register}
                                errors={errors}   
                                surveys={surveys}
                                mailTemplates={mailTemplates}
                                state={state}
                                setState={setState} 
                                defaultTouchPoints={defaultTouchPointsArray}
                                formValues={formValues}
                                trigger={trigger}
                                imageURL={imageURL}
                                setImageURL={setImageURL}
                                file={file}
                                setFile={setFile}
                                activeItem={activeItem}
                                trainingToEdit={trainingToEdit}
                                trainingToEditLocal={trainingToEditLocal}
                                setTrainingToEditLocal={setTrainingToEditLocal}
                                certificateTemplates={certificateTemplates}
                                locations={locations}
                                editor={editor}
                            />
                        </form>
                        <DevTool control={control}/>
                        <Footer 
                            activeItem={activeItem}
                            setActiveItem={setActiveItem}
                            navigationItems={navigationItems}
                            trigger={trigger}
                            formRef={formRef}
                        />
                    </>
                </AddOrEditTrainingContext.Provider>
            </AddOrEditTrainingSC>
        </Modal>
    );
};

const AddOrEditTrainingSC = styled.div`
    min-height : 95vh; 
    min-width : 95vw; 
    max-height : 95vh;
    max-width : 95vw;
    display : flex;
    flex-direction : column;
    background-color : white;
    border-radius : 10px;
    box-shadow : 0 0 10px 0 rgba(0,0,0,0.1);
    position : relative;
    overflow : hidden;

    &>form {
        display : flex;
        flex-direction : column;
        flex-grow : 1;
        overflow : hidden;
    }
`

export default AddOrEditTraining;