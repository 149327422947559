import React from 'react';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';

const PricePerDay  = ({ register, errors, trainingToEditLocal}) => {
    
    const rendering = () => {
        return (
            <PricePerDaySC>
                <TextField  
                    disabled={trainingToEditLocal?.customProperties?.free}
                    label='Price per day'
                    type='number' 
                    defaultValue={trainingToEditLocal.pricePerDay ? trainingToEditLocal.pricePerDay : 0}
                    {...register('price', {
                        required : {
                            value : true,
                            message : 'Price is required'
                        }
                    })}
                    error={!!errors.price}
                    helperText={errors.price?.message}
                    sx={{
                        maxWidth : '49%',
                        marginRight : '10px'
                    }}
                />
                <TextField  
                    label='Number of days'
                    type='number' 
                    defaultValue={trainingToEditLocal.numberOfDays ? trainingToEditLocal.numberOfDays : 1}
                    {...register('numberOfDays', {
                        required : {
                            value : true,
                            message : 'Number of days is required'
                        }
                    })}
                    error={!!errors.numberOfDays}
                    helperText={errors.numberOfDays?.message}
                    sx={{
                        width : '49%'
                    }}
                />
            </PricePerDaySC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const PricePerDaySC = styled.div`
    display : flex;
`

export default PricePerDay