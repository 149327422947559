import React from 'react';
import styled from 'styled-components';

const ToggleJobsButton = ({ state , setState }) => {

    const rendering = () => {
        return (
            <ToggleJobsButtonSC>
                <button
                    onClick={() => setState({...state, jobsListToggled : true })}
                >
                    Show Jobs
                </button>
            </ToggleJobsButtonSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const ToggleJobsButtonSC = styled.div`
        position : relative; 
    padding : 10px;
    padding-left : 0;
    padding-right : 0;
    margin-right : 10px;

    >button {
        min-height : 42px;
        font-family : inherit;
        display : flex;
        justify-content : space-between;
        padding : 23px;   
        background-color : white;
        box-shadow : 0 0 10px 0 rgba(0,0,0,0.1);
        border-radius : 5px;
        box-sizing : border-box;
        border : 0;
        margin-right : 10px;

        &:hover {
            background-color : #e5e5e5;
            cursor : pointer; 
        }
    }
`

export default ToggleJobsButton