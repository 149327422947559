import React from 'react';
import styled from 'styled-components';
import { App } from './BarChart'; 
import Axios from 'axios'; 
import { backEndAdress } from '../../../variables/back-endAdress';
import { SurveyContext } from '../Survey';
import BarChart2 from './BarChart2';
import Chart from 'react-google-charts';
import FreeText from './FreeText';
import Rating from './Rating';
import SurveyFilterSideBar from '../SurveyFilterSideBar/SurveyFilterSideBar';

export const SurveyResponsesContext = React.createContext();

const SurveyResponses = () => {

    const { surveyIdFromUrl } = React.useContext(SurveyContext);

    const [currentSurveyResponses, setCurrentSurveyResponses] = React.useState(null);
    const [survey, setSurvey] = React.useState(null);
    const [arrayOfData, setArrayOfData] = React.useState(null);
    const [filteredResponses, setFilteredResponses] = React.useState([]);

    const [trainings, setTrainings] = React.useState(null);
    const [locations, setLocations] = React.useState(null);
    const [trainers, setTrainers] = React.useState(null);

    const [filters, setFilters] = React.useState(null); 

    {
        
        React.useEffect(() => {

            const getFilteredSurveyReponses = async () => {

                try {
                    const fetchResponse = await Axios.post(`${backEndAdress}/api/survey-responses/by-survey-with-filters/${surveyIdFromUrl}`, filters );
                    const responses = fetchResponse.data.map((item) => item.responses);
            
                    let final = [];

                    responses.map((item) => {
                        item.map((response) => {
                            final.push({
                                question : response.question,
                                type : response.type,
                                answer : response.possibleAnswers.length>0 ? response.possibleAnswers[response.answerIndex-1] : response.answerIndex,
                            });
                        })
                    })

                    return final;

                } catch (error) {
                    console.log(error)       
                }
            }

            const groupAnswers = (surveyResponses) => {
                const result = {}

                surveyResponses.map((surveyItem) => {

                        const { question , answer } = surveyItem;

                        if(!result[question]) {
                            result[question] = {};
                        }

                        if (!result[question][answer]) {
                            result[question][answer] = 0;
                        }

                        result[question][answer]++;

                })

                return result; 
                
            }

            const changeTheFormat = (groupedAnswers) => {
                let result = [];

                for(let key in groupedAnswers) {
                    
                    if (groupedAnswers.hasOwnProperty(key)) { 
                        
                        const objectTemp = {
                            question : key,
                            data : [
                                ["Answer", "number of answers"]
                            ]
                        };

                        function dictToArray(dict) {
                            let array = [];
                            for (const [key, value] of Object.entries(dict)) {
                            array.push([key, value]);
                            }
                            return array;
                        }

                        const answers = dictToArray(groupedAnswers[key]);
                        objectTemp.data = objectTemp.data.concat(answers);
                        result.push(objectTemp);

                    }
                }

                return result; 
            }

            const runFunction = async () => {
                const surveyResponses = await getFilteredSurveyReponses();
                const groupedAnswers = groupAnswers(surveyResponses);
                const final = changeTheFormat(groupedAnswers);
                const addingTypes = final.map((item) => {
                    return {
                        ...item,
                        type : surveyResponses.find((response) => response.question === item.question).type
                    }
                });
                setArrayOfData(addingTypes);
            }

            filters && runFunction();

        },[filters]);

        React.useEffect(() => {

            const getTrainings = async () => {
                try {
                    const response = await Axios.get(`${backEndAdress}/api/trainings`);
                    setTrainings(response.data);
                    return response.data;
                } catch (error) {
                    console.log(error)
                }
            }

            const getLocations = async () => {
                try {
                    const response = await Axios.get(`${backEndAdress}/api/locations`);
                    setLocations(response.data);
                    return response.data;
                } catch (error) {
                    console.log(error)
                }
            }

            const getTrainers = async () => {
                try {
                    const response = await Axios.get(`${backEndAdress}/api/trainers`);
                    setTrainers(response.data);
                    return response.data;
                } catch (error) {
                    console.log(error)
                }
            }

            const trainingIdFromUrl = window.location.hash.split('/')[3];
            
            const createInitFilters = (trainings, locations, trainers) => {
                const filters = [{
                    filterTitle : 'Trainer',
                    possibleValues : trainers,
                    filterProperty : 'instance.trainer._id',
                    filterValues : [],
                    propertyToShow : 'firstName'
                },{
                    filterTitle : 'Location',
                    possibleValues : locations,
                    filterProperty : 'instance.location._id',
                    filterValues : [],
                    propertyToShow : 'title'
                },{
                    filterTitle : 'Training',
                    possibleValues : trainings,
                    filterProperty : 'training._id',
                    filterValues : [],
                    propertyToShow : 'name'
                }]

                return filters; 
            };

            const runFunctions = async () => {
                const trainings = await getTrainings();
                const locations = await getLocations();
                const trainers = await getTrainers();
                const initFiltersObject = createInitFilters(trainings, locations, trainers);
                setFilters(initFiltersObject);
            }

            runFunctions();


        },[]);

    }

    const pickComponent = (item) => {
        switch(item.type) {
            case 'free-text':
                return (
                    <FreeText textItems={item.data} />
                )
            case 'multiple-choice':
                return (
                    <Chart
                        chartType="PieChart"
                        data={item.data}
                        width={"100%"}
                        height={"300px"}
                    />
                ) 
            case 'rating' : 
                return (
                    <Rating 
                        item={item}
                    />
                )
            default:
                return <p>default</p>
        }
    }

    const rendering = () => {
        if(!arrayOfData) return <p>Loading...</p>
        if(!trainings || !locations || !trainers) return <p>Loading...</p>

        return (
            <SurveyResponsesContext.Provider value={{ 
                filters,
                setFilters,
                trainings,
                locations,
                trainers,
                arrayOfData
            }}>
                <SurveyResponsesSC>
                    <SurveyFilterSideBar />

                    {arrayOfData.length === 0 && <p>No responses yet</p>}

                    <div className='container-wrapper'>
                        <div className='container'>
                            {arrayOfData.map((item, index) => {
                                return (
                                    <div key={index} className='response-item'>
                                        <p className='question-title'>{item.question}</p>
                                            {pickComponent(item)}

                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </SurveyResponsesSC>
            </SurveyResponsesContext.Provider>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SurveyResponsesSC = styled.div`
    flex-grow : 1;
    display : flex;

    .container-wrapper {
        flex-grow : 1;
        display : flex;
        justify-content : center;


        .container {
            display : flex;
            flex-direction : column;
            max-width : 840px;
            min-width : 840px;

        .response-item {
            background-color : white;
            border-radius : 5px;
            box-shadow : 0px 0px 5px rgba(0,0,0,0.1);
            margin-top : 25px;

            &:last-child {
                margin-bottom : 25px;
            }
            
            .question-title {
            padding : 25px;

                font-size : 1.2rem;
                border-bottom : 1px solid lightgray;
            }
        }
    }
    }


`

export default SurveyResponses