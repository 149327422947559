import React from 'react';
import styled from 'styled-components';
import Modal from '../GeneralComponents/Modal';
import { AppContext } from '../../App';
import Axios from 'axios'; 
import { backEndAdress } from '../../variables/back-endAdress';
import TemplatesGrid from './browseTemplates/TemplatesGrid';
import SearchbarTemplates from './browseTemplates/SearchBarTemplates';
import MailPreviewerDiv from './browseTemplates/MailPreviewerDiv';
import { Button, ButtonBase } from '@mui/material';
import LoadTemplateScreen from '../emailSchedule/loadTemplateScreen/LoadTemplateScreen';
import { defaultMergeTags } from '../../variables/defaultMergeTags';

const PopupSelectMailTemplate = ({ state , setState }) => {
    
    const { appState, setAppState } = React.useContext(AppContext);
    const [searchQuery , setSearchQuery] = React.useState('');

    const fetchTemplates = async () => {
        try {
            const response = await Axios.get(`${backEndAdress}/api/mail-templates/find-all/${appState.tenant}`);
            setState({...state, templates : response.data, currentMailTemplate : null})
        } catch (error) {
            console.log(error);
        }
    }

    React.useEffect(() => {
        fetchTemplates();
        setSearchQuery('');
    },[])

    const loadDesignInEditor = () => {
        setState({
            ...state, 
            designToLoad : state.currentMailTemplate
        });
    }

    const setEmailTemplate = (emailTemplate) => {
        setState({...state, currentMailTemplate : emailTemplate})
    }

    const handleBarsObject ={
        //user 
            first_name: defaultMergeTags.first_name,
            last_name: defaultMergeTags.last_name,
            imageURL : null,

        //Trainer
            trainer_name : defaultMergeTags.trainer_name,
            trainer_email : defaultMergeTags.trainer_email,
            trainer_phone : defaultMergeTags.trainer_phone,
            trainer_description : defaultMergeTags.trainer_description,
            trainer_image : defaultMergeTags.trainer_image, 

        //Training
            training_name : defaultMergeTags.training_name, 
            dates_concatted : null,
            short_description : defaultMergeTags.short_description,
            requirements : 'to be added',

        //Location
            adress : defaultMergeTags.adress,
            instructions : defaultMergeTags.instructions,
            instructions_image_url : defaultMergeTags.instructions_image_url,

        //Survey 
            survey_link : null
    }

    const rendering = () => {
        if(state.templates.length === null) return;
        return (
            <Modal>
                <PopupSelectMailTemplateSC>

                    <div className='top-row'>
                        <p>select a template</p>
                        <Button
                            variant='contained'
                            onClick={() => setState({...state, selectDesignPopUp : false})}
                        >
                            close
                        </Button>
                    </div>

                    <div className='main-content'>
                        {state.templates && 
                            <LoadTemplateScreen 
                                mailTemplates={state.templates}
                                mailTemplate={state.currentMailTemplate}
                                setEmailTemplate={setEmailTemplate}
                                handleBarsObject={handleBarsObject}
                            />
                        }


                        
                        {/* <div className='left-side'>
                            <SearchbarTemplates
                                state={state}
                                setState={setState}
                                searchQuery={searchQuery}
                                setSearchQuery={setSearchQuery}
                            />

                            <TemplatesGrid 
                                state={state}
                                setState={setState}
                                searchQuery={searchQuery}
                            />
                        </div>
                        <div className='right-side'>
                            {state.currentMailTemplate && <MailPreviewerDiv 
                                template={state.currentMailTemplate}
                                handleBarVariables={state.handleBarVariables}
                            />}
                        </div> */}

                    </div>

                    <div className='buttons'>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={() => loadDesignInEditor()}
                        >
                            Load template
                        </Button>
                    </div>

                </PopupSelectMailTemplateSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const PopupSelectMailTemplateSC = styled.div`
    background-color : white; 
    width : 95vw;
    height : 90vh;
    max-height : 90vh;
    overflow : hidden;
    display : flex;
    flex-direction : column;
    position : relative;
    border-radius : 10px;
    padding : 15px;

    .top-row {
        display : flex;
        justify-content : space-between;
        padding : 15px;
    }

    .main-content {
        display : flex;
        flex-grow : 1;
        overflow : hidden;
    }

    .buttons {
        position : absolute; 
        justify-content : center;
        padding : 15px;
        bottom : 15px;
        right : 15px;
    }

`

export default PopupSelectMailTemplate