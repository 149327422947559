import React from 'react';
import styled from 'styled-components';
import { colors } from '../../variables/Colors';

const TrainingTile = ({ training, setState, state, instancesCount}) => {

    const rendering = () => {
        return (
            <TrainingTileSC 
                onClick={() => setState({...state, preOpenTraing : training})}   
            >
                {/* <div className='background-image' style={{backgroundImage : `url('/test.png')`}}></div> */}
                <div className='background-image' style={{backgroundImage : `url(${training.imageUrl})`}}></div>
                <p className='training-title'>{training.name}</p>
                <p className='number-of-instances'>{`${instancesCount} Instances`}</p>
            </TrainingTileSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingTileSC = styled.div`
    background-color : white;
    border-radius : 5px;
    background-size : cover;    
    background-position : center;
    position : relative; 
    padding : 5px;
    min-height : 280px;
    box-shadow : 0 0 10px 0 rgba(0,0,0,0.1);

    .background-image { 
        aspect-ratio: 16 / 10;
        background-size : cover;
        background-position : center;
        border-radius : 5px;
    }

    .training-title {
        margin-top : 10px;
        font-size : 22px;
        line-height : 0.95;
        font-weight : 400;
        padding : 10px;
        padding-bottom : 0;
    }

    .number-of-instances {
        font-size : 16px;
        font-weight : 300;
        padding : 10px;
        padding-top : 5px;
        color : ${colors.lightGrey};
    }



    &:hover {
        cursor : pointer;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.5); /* White color with 50% opacity */
        }    
    }


`

export default TrainingTile