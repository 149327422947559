import React from 'react';
import styled from 'styled-components';
import Difficulty from './Difficulty';
import AvailableLanguages from './AvailableLanguages';
import AvailableLocations from './AvailableLocations';
import OnDemand from './OnDemand'; 
import Webinar from './Webinar';
import FreeTraining from './FreeTraining';
import PricePerDay from './PricePerDay';
import TextBlock from './TextBlock';
import { v4 as uuidv4 } from 'uuid';
import TipTapEditor from './TipTapEditor';

const TrainingProperties = ({ visible, locations, state, setState, register, errors, trainingToEditLocal, setTrainingToEditLocal, editor}) => {

    const rendering = () => {
        return (
            <TrainingPropertiesSC style={{ display : `${visible ? 'flex' : 'none'}`}}>
                <div className='form-group'>
                    {/* <p className='properties-title'>Training properties</p> */}
                        <div className='input-dropdown-container'>
                        <Difficulty
                            setTrainingToEditLocal={setTrainingToEditLocal}
                            trainingToEditLocal={trainingToEditLocal}
                        />
                        <AvailableLanguages 
                            setTrainingToEditLocal={setTrainingToEditLocal}
                            trainingToEditLocal={trainingToEditLocal}
                        />
                        <AvailableLocations 
                            locations={locations} 
                            state={state} 
                            setState={setState}
                            setTrainingToEditLocal={setTrainingToEditLocal}
                            trainingToEditLocal={trainingToEditLocal}
                        />
                        </div>
                        <div className='selectable-properties'>
                            <OnDemand 
                                setTrainingToEditLocal={setTrainingToEditLocal}
                                trainingToEditLocal={trainingToEditLocal}
                            />
                            <Webinar 
                                setTrainingToEditLocal={setTrainingToEditLocal}
                                trainingToEditLocal={trainingToEditLocal}
                            />
                            <FreeTraining 
                                setTrainingToEditLocal={setTrainingToEditLocal}
                                trainingToEditLocal={trainingToEditLocal}
                            />
                        </div>
                        <PricePerDay 
                            register={register} 
                            errors={errors} 
                            setTrainingToEditLocal={setTrainingToEditLocal}
                            trainingToEditLocal={trainingToEditLocal}
                        />
                </div>
                {/* <div className='text-blocks'>
                    {trainingToEditLocal.textBlocks.map((textBlock, index) => {
                        return (
                            <TextBlock
                                key={textBlock.textBlockId}
                                textblock={textBlock}
                                state={state}
                                trainingToEditLocal={trainingToEditLocal}
                                setTrainingToEditLocal={setTrainingToEditLocal}
                            />
                        )
                    })}
                    <button 
                        className='add-text-block'
                        onClick={() => {
                            setTrainingToEditLocal({
                                ...trainingToEditLocal,
                                textBlocks : [...trainingToEditLocal.textBlocks, {
                                    title : 'Enter title',
                                    content : '',
                                    textBlockId : uuidv4()
                                }]
                            })
                        }}
                    >
                        Add text block
                    </button>
                </div> */}
                <TipTapEditor
                    editor={editor}
                />
            </TrainingPropertiesSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingPropertiesSC = styled.div`
    display : flex;
    padding : 25px;
    gap : 20px;
    
    .form-group {
        min-width : 30%;
        margin-top : 42px;
        .properties-title {
            margin-bottom : 25px;
        }

        .input-dropdown-container {
            display : flex;
            gap : 15px;
            flex-direction : column;
            margin-bottom : 15px;

        }

        .selectable-properties {
            display : grid; 
            grid-template-columns : repeat(2, 1fr);
            gap : 10px;
            margin-bottom : 20px;

            &>div {
                display : flex; 
                border : 1px solid lightgrey;
                padding : 10px;
                padding-left : 15px;
                box-sizing : border-box;
                border-radius : 5px;
                min-height : 56px;
                max-height : 56px;
                align-items : center;
                justify-content : space-between;
            }
        }
    }



`

export default TrainingProperties