import React from 'react';
import styled from 'styled-components';

const CardObject = ({ title, value, subTitle, icon, backgroundColor }) => {

    const rendering = () => {
        return (
            <CardObjectSC>
                <div 
                    className='image-card-object' 
                    style={{backgroundColor : backgroundColor}}
                    // style={{backgroundImage : `url(${imageUrl})`}}
                >
                    {icon}
                </div>
                <div className='title-card-information'>
                    <p className='title-kpi'>{title}</p>
                    <p className='kpi-value'>{value}</p>
                    <p className='kpi-subtitle'>{subTitle}</p>
                </div>
            </CardObjectSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const CardObjectSC = styled.div`
    background-color : white;
    border-radius : 10px;
    min-height : 120px;
    min-width : 380px;
    margin : 15px;
    margin-right : 0;
    display : flex;

    .image-card-object {
        min-height : 120px;
        aspect-ratio : 1;
        background-color : #f2f2f2;
        background-size : cover;
        background-position : center;
        overflow : hidden;
        border-radius : 10px 0px 0px 10px;
        display : flex;
        justify-content : center;
        align-items : center;
        background-color : #4a8fff;

        &>svg {
            width : 50px;
            height : 50px;
            fill : white;
        }
    }

    .title-card-information {
        display : flex;
        flex-direction : column;
        padding : 15px;
        padding-top : 0;
        justify-content : center;
        padding-left : 25px;

        .title-kpi {
            font-size : 1.3em;
            font-weight : 300;
        }

        .kpi-value {
            font-size : 2em;
            font-weight : bold;
        }

        .kpi-subtitle {
            font-size : 0.8em;
            color : grey;
        }
    }

`

export default CardObject