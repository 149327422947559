import React from 'react';
import styled from 'styled-components';
import RegularFlowBar from './RegularFlowBar';
import CancelFlowBar from './CancelFlowBar';
import { v4 as uuidv4 } from 'uuid';
import Axios from 'axios';
import { backEndAdress } from '../../variables/back-endAdress';
import IndividualTouchPoint2 from '../emailSchedule/individualTouchPoint2/IndividualTouchPoint';

const MailSchedule = ({ 
    mailTemplates,  
    defaultTouchPoints, 
    state : parentState, 
    setState : setParentState,
    training,
    trainingLocation,
    trainer,
    trainingToEditLocal,
    setTrainingToEditLocal
}) => {

    const [state, setState] = React.useState({
        touchPoints : [],
        individualTouchPoint : null,
        currentEmailTemplate : null,
        loadingEmailTemplate : false,
        templateFile : null,
        reRenderer : 1,
        handleBarVariables : null
    });

    React.useLayoutEffect(() => {
        setState({
            ...state,
            touchPoints : trainingToEditLocal.defaultTouchPointsArray
        });
    },[]);

    //initializing the touchPointsArray
    React.useEffect(() => {
        setTrainingToEditLocal({
            ...trainingToEditLocal,
            defaultTouchPointsArray : state.touchPoints
        })
    },[state.touchPoints]);

    const addTouchPoint = () => {
        const newTouchPoint = {
            touchPointId : uuidv4(),
            name : '',
            type : null, 
            trigger : null
        }

        setState({
            ...state,
            individualTouchPoint : newTouchPoint
        })
    }

    const selectMailFunction = (individualTouchPoint) => {
        setState({
            ...state,
            individualTouchPoint : individualTouchPoint
        })
    }

    const removeTouchPoint = (touchPointId) => {
        const touchPoints = state.touchPoints.filter(touchPoint => touchPoint.touchPointId !== touchPointId);
        console.log('touchpints : ', state.touchPoints);
        console.log('updated : ' , touchPoints);
        console.log('id to remove', touchPointId);
        
        setState({
            ...state, 
            touchPoints : touchPoints, 
            individualTouchPoint : null
        });
    }

    const rendering = () => {

        const touchPointsRegularFlow = state.touchPoints.filter(touchPoint => touchPoint.selectedTrigger !== 'cancel');
        return (
            <MailScheduleSC>
                {state.individualTouchPoint && 
                    <div className='individual-touch-point-bar'>
                        <IndividualTouchPoint2 
                            state={state}
                            setState={setState}
                            mailTemplates={mailTemplates}
                            currentInstance={parentState.currentInstance}
                            removeTouchPoint={removeTouchPoint}
                        />
                    </div>
                }

                <RegularFlowBar 
                    addTouchPoint={addTouchPoint}
                    selectMailFunction={selectMailFunction}
                    touchPoints={touchPointsRegularFlow}
                    state={state}
                    setState={setState}
                    parentState={parentState}
                />
                <CancelFlowBar 
                    addTouchPoint={addTouchPoint}
                    selectMailFunction={selectMailFunction}
                    state={state}
                    setState={setState}
                />
            </MailScheduleSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const MailScheduleSC = styled.div`
    flex-grow  : 1;
    display : flex;
    flex-direction : column; 
    background-color : #fafafa;
    padding-top : 15px;
    
    &>.mail-flow-row {
        min-height : 220px;
        max-height : 220px;
        margin : 0;
        display : flex;
        padding : 15px;
        padding-left : 35px;
        box-sizing : border-box;
    }

    .individual-touch-point-bar {
        top : 0; 
        left : 0;
        bottom : 0; 
        right : 0;
        position : absolute;
        background-color : red;
        display : flex;
        z-index : 100;
        
    }
`

export default MailSchedule