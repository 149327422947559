import React from 'react';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import AddImage from './AddImage';
import ImageUpload from '../../../GeneralComponents/ImageUpload';

const BasicInfo = ({ register, errors, trigger, imageURL, setImageURL, file, setFile, visible, trainingToEdit }) => {

    const handleImageUpload = (e) => {
        setFile(e.target.files[0]);
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setImageURL(reader.result);
        }
    }

    const rendering = () => {

        return (
            <BasicInfoSC style={{ display : `${visible ? 'flex' : 'none'}`}}>
                <div className='form-group'>
                    <TextField  
                        label='Title'
                        type='text' 
                        defaultValue={trainingToEdit.name}
                        {...register('title', {
                            required : {
                                value : true,
                                message : 'title is requried'
                            },
                            minLength : {
                                value : 5,
                                message : 'This field must be at least 5 characters'
                            }
                        })}
                        error={!!errors.title}
                        helperText={errors.title?.message}
                        sx={{
                            marginBottom: '20px'
                          }}
                    />
                    <div className='filler'>
                    <TextField  
                        defaultValue={trainingToEdit.description}
                        label='Description'
                        multiline
                        type='text' 
                        {...register('description', {
                            required : {
                                value : true,
                                message : 'description is requried'
                            },
                            minLength : {
                                value : 5,
                                message : 'This field must be at least 5 characters'
                            }
                        })}
                        sx={{
                            width: '100%',
                            height: '100%', // Ensure the text field takes the full height of the parent div
                            '& .MuiInputBase-root': {
                              height: '100%', // Ensures input area fills the div,
                              alignItems: 'flex-start',
                            },
                          }}
                        error={!!errors.description}
                        helperText={errors.description?.message}
                    />
                    </div>
                </div>
                <div className='image-upload-container'>
                    <ImageUpload
                        imageURL={imageURL}
                        setImageURL={setImageURL}
                        file={file}
                        setFile={setFile}
                        id={'thumbnail-image-upload'}
                        description={'Upload thumbnail image'}
                    />
                </div>
            </BasicInfoSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const BasicInfoSC = styled.div`
    flex : 1;
    display : flex;


    .form-group {
        padding : 20px;
        flex : 1;
        display : flex;
        flex-direction : column;
        max-width : 50%;

        .filler {
            flex : 1;
        }
    }

    .image-upload-container {
        margin-top : 20px;
        min-width : 350px;
        max-width : 350px;
        max-height : 250px;
        display : flex;
        flex-direction : column;
        align-items : center;
    }
`

export default BasicInfo