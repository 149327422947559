import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const CertificateTemplateOptions = ({ state , setState }) => {

    const rendering = () => {
        return (
            <CertificateTemplateOptionsSC>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setState({
                        ...state, 
                        individualTemplate : {
                        name : '',
                        text : '',
                        imageURL : ''
                    }
                })}
                >
                    Add template
                </Button>
            </CertificateTemplateOptionsSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const CertificateTemplateOptionsSC = styled.div`
    position : absolute;
    bottom : 10px;
    right : 10px;
`

export default CertificateTemplateOptions