import React from 'react';
import styled from 'styled-components';
import TrainersGrid from './TrainersGrid';
import Axios from 'axios';
import { backEndAdress } from '../../variables/back-endAdress'; 
import TrainerInfo from './individualTrainerModal/TrainerInfo';
import { CircularProgress } from '@mui/material';
import { AppContext } from '../../App';
import IndividualTrainer from './individualTrainer2/IndividualTrainer';

export const TrainersOverviewContext = React.createContext();

const TrainersOverview = () => {

    const { appState } = React.useContext(AppContext);
    const { tenant } = appState

    const [trainersState, setTrainersState] = React.useState({
        individualTrainer : null,
        trainers : [],
        loading : true 
    });

    //handle the file upload
    const [file, setFile] = React.useState(null);
    const [imageURL, setImageURL] = React.useState(null);

    React.useEffect(() => {
        if(trainersState.individualTrainer) return;

        const getTrainers = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/trainers/get-all/${tenant}`);
                const trainers = response.data;
                console.log('trainers,', trainers)
                setTrainersState({
                    ...trainersState,
                    trainers,
                    loading : false
                });
            } catch (error) {
                console.log('error', error);
            }
        }

        getTrainers();

    }, []);       

    const deleteTrainer = () => {
        const deleteTrainerFunction = async () => {
            try {
                const response = await Axios.delete(`${backEndAdress}/api/trainers/delete-trainer/${appState.tenant}/${trainersState.individualTrainer._id}`);
                setTrainersState({
                    ...trainersState,
                    individualTrainer : null,
                    loading : false,
                    trainers : response.data
                });
            } catch (error) {
                console.log('error', error);
            }
        }

        deleteTrainerFunction();
    }

    const openModalNewTrainer = () => {

        setFile(null);
        setImageURL(null);

        setTrainersState({
            ...trainersState,
            individualTrainer : {
                firstName : '',
                lastName : '',
                email : '',
                phone : '',
                linkedIn : '',
                shortDescription : ''
            }
        });
    }

    const handleImageUpload = (e) => {
        setFile(e.target.files[0]);
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setImageURL(reader.result);
        }
    }

    const rendering = () => {
        if(trainersState.loading) return (
            <TrainersOverviewSC>
                <div className='loading-screen'>
                    <CircularProgress />
                </div>
            </TrainersOverviewSC>
        );

        return (
            <TrainersOverviewContext.Provider value={{
                trainersState,
                setTrainersState,
                openModalNewTrainer,
                handleImageUpload,
                file,
                imageURL,
                setFile,
                setImageURL,
                deleteTrainer,
            }}>
                <TrainersOverviewSC>
                    <>
                        {trainersState.individualTrainer && 
                            <IndividualTrainer 
                                trainer={trainersState.individualTrainer} 
                                file={file}
                                setFile={setFile}
                                imageURL={imageURL}
                                setImageURL={setImageURL}    
                                trainersState={trainersState}
                                setTrainersState={setTrainersState}
                            />
                        }
                    </>
                    <TrainersGrid />
                </TrainersOverviewSC>
            </TrainersOverviewContext.Provider>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainersOverviewSC = styled.div`
    display: flex;
    flex-grow : 1;
    min-height : 100%;
    position : relative;

    .loading-screen {
        position : absolute;
        top : 0; 
        left : 0;
        right : 0; 
        bottom : 0;
        display : flex;
        justify-content : center;
        align-items : center;
    }
`

export default TrainersOverview