export const defaultMergeTags = {

    //user 
    first_name: 'John',
    last_name: 'Doe',
    imageURL : null,

    //Trainer
    trainer_name : 'John Doe',
    trainer_email : 'trainer@holli.com',
    trainer_phone : '03667212',
    trainer_description : 'Hank is a ficitional trainer. This description should be replaced with the actual trainer description',
    trainer_image : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png',

    //Training
    training_name : 'Test training',
    dates_concatted : [],
    short_description : 'This is a test training description, this should be replaced with the actual training description',
    requirements : 'Please bring your own pc',

    //Location
    adress : 'Test street 123',
    instructions : 'Test street is a test location, this should be replaced with the actual location instructions',
    instructions_image_url : 'https://upload.wikimedia.org/wikipedia/en/thumb/5/56/Google_maps_screenshot.png/300px-Google_maps_screenshot.png',

//Survey 
    survey_link : null
}