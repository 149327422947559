import React from 'react';
import styled from 'styled-components';
import Modal from '../../GeneralComponents/Modal';
import BasicInput from '../../GeneralComponents/BasicInput';
import { SurveyContext } from './FillSurveyLink';
import SchoolIcon from '@mui/icons-material/School';
import { Button, CircularProgress } from '@mui/material';
import { colors } from '../../../variables/Colors';
import FigmaInput from '../../GeneralComponents/FigmaInput';
import CertCreatedMenu from './CertCreatedMenu';

const PreSendMenu = () => {

    const { firstnameref, lastnameref, emailref, state, setState, sendSurvey, user, instance } = React.useContext(SurveyContext);

    const rendering = () => {
        return (
            <Modal>

                {state.sendingSurveyLoading ? 

                    <PreSendMenuSC>
                        <div className='loading-send-cert'>
                            <p>Creating certificate</p>
                            <CircularProgress />
                        </div>
                    </PreSendMenuSC>

                    :

                    state.certCreated ?
                        <CertCreatedMenu certificate={state.certCreated}/>
                            :
                        <PreSendMenuSC>
                            <p className='menu-div-title'>Send certificate to</p>
                            <div className='top-row'>
                                <div className='left-panel'>
                                    <FigmaInput
                                        label='mail'
                                        defaultValue={user.email}
                                        reference={firstnameref}
                                    />

                                </div>
                            </div>
                            <div className='buttons'>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    style={{marginRight : '10px'}}
                                    onClick={sendSurvey}
                                >
                                    Send
                                </Button>
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={() => setState({...state, preSendToggled : false})}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </PreSendMenuSC>
                    }
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const PreSendMenuSC = styled.div`
    background-color : white;
    padding : 40px;
    min-width : 500px;
    display : flex;
    flex-direction : column;
    border-radius : 5px;
    box-shadow : 0 0 10px rgba(0,0,0,0.1);

    .loading-send-cert {
        display : flex;
        justify-content : center;
        align-items : center;
        height : 200px;
        flex-direction : column;

        &>p {
            margin-bottom : 20px;
        }
    }

    .menu-div-title {
        font-size : 1.5rem;
        margin-bottom : 15px;
        margin : 15px;
    }

    h1 {
        font-size : 1.5rem;
        margin-bottom : 25px;
        margin-top : 0;
    }

    .top-row {
        display : flex;
        margin-bottom : 15px;
        flex-direction : column;

        .left-panel {
            display : flex;
            flex-direction : column;
            justify-content : space-between;
        }

        .right-panel {
            display : flex;
            justify-content : center;
            align-items : center;
            min-width : 50%;
            border : 1px solid ${colors.darkGrey};
            margin-left : 15px;
            border-radius : 5px;

            &>svg {
                font-size : 100px;
                color : ${colors.darkGrey};
            }
        }
    }

    .buttons {
        margin : 10px;
    }


`

export default PreSendMenu