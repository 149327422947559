import React from 'react';
import { Button, Box } from '@mui/material';
import styled from 'styled-components';

const Footer = ({ activeItem, navigationItems, setActiveItem, trigger, formRef }) => {
     
    const submitFunction = (e) => {
        formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }

    return (
        <FooterSC>
            <div className='container'>
                <div className='buttons'>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setActiveItem(activeItem - 1)}
                    >
                        previous
                    </Button>
                    {activeItem === navigationItems.length - 1 ?
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={(e) => submitFunction(e)}
                        >
                            Submit
                        </Button>
                        :
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setActiveItem(activeItem + 1)}
                            >
                            next
                        </Button>
                    }
                </div>
            </div>
        </FooterSC>
    );
};

const FooterSC = styled.div`
    display : flex;
    align-items : center;
    padding : 20px;
    border-bottom-left-radius : 10px;
    border-bottom-right-radius : 10px;
    justify-content : flex-end;

    p {
        font-size : 0.8rem;
        color : grey; 
        justify-content : flex-end;
        display : flex;
        padding-top : 5px;
        padding-right : 5px;

    }
    button {
        margin : 0;
        margin-right : 10px;

    }
    p {
        margin : 0;
    }
`;

export default Footer;