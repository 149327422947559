import React from 'react';
import styled from 'styled-components';
import MailSchedule from '../../MailScheduleComponent/MailSchedule';

const TrainingMailSchedule = ({ 
    state, 
    setState, 
    mailTemplates, 
    touchPointsForTraining,
    visible,
    trainingToEditLocal,    
    setTrainingToEditLocal
}) => {

    const rendering = () => {
        return (
            <TrainingMailScheduleSC style={{ display : `${visible ? 'flex' : 'none'}`}}>
                <MailSchedule 
                    state={state}
                    setState={setState}
                    mailTemplates={mailTemplates}
                    defaultTouchPoints={touchPointsForTraining}
                    trainingToEditLocal={trainingToEditLocal}
                    setTrainingToEditLocal={setTrainingToEditLocal}
                />
            </TrainingMailScheduleSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingMailScheduleSC = styled.div`
    flex-grow :1;
    overflow-x : scroll;
    display : flex;
    flex-direction : column;
`

export default TrainingMailSchedule