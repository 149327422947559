import React from 'react';
import styled from 'styled-components';
import JobsList from '../../mailTraffic/jobsList/JobsList';
import Modal from '../../GeneralComponents/Modal';
import JobsListHeader from './JobsListHeader';
import JobPreviewComponent from './JobPreviewComponent';

const JobsListComponent = ({ mailJobs, setState, state }) => {

    const [jobPreview, setJobPreview] = React.useState(null);

    const selectJob = (job) => {
        setJobPreview(job);
    }


    const rendering = () => {
        return (
            <Modal>
                <JobsListComponentSC>
                    <>
                        {jobPreview && <JobPreviewComponent jobPreview={jobPreview} setJobPreview={setJobPreview}/>}
                    </>
                    <JobsListHeader setState={setState} state={state}/>
                    <JobsList
                        mailJobs={mailJobs}
                        selectJob={selectJob}
                    />
                </JobsListComponentSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const JobsListComponentSC = styled.div`
    min-height : 90vh;
    min-width : 90vw;
    background-color : white; 
    border-radius : 10px;
    display : flex;
    flex-direction : column;
    box-shadow : 0px 0px 10px 0px rgba(0,0,0,0.1);    
`

export default JobsListComponent