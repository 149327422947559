import React from 'react';
import styled from 'styled-components';
import { TextField , Button, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';
import { backEndAdress } from '../../variables/back-endAdress';
import moment from 'moment';
import { AppContext } from '../../App';

const ReactForm = ({ instance, state , setState }) => {

    const { appState } = React.useContext(AppContext);
    const { tenant } = appState;

    const navigate = useNavigate();
    const form = useForm({
        defaultValues : {
            firstName : '',
            lastName : '',
            email : '',
            company : '',
            role : '',
        }
    });

    const { register, handleSubmit, formState } = form;
    const { errors } = formState;

    const onSubmit = (data) => {

        const addAttendee = async () => {
            try {
                const location = await Axios.get(`${backEndAdress}/api/locations/${instance.location._id}`);
                const training = await Axios.get(`${backEndAdress}/api/trainings/${instance.trainingId}`);
                const trainer = await Axios.get(`${backEndAdress}/api/trainers/${instance.trainer._id}`);

                console.log('trainer' , trainer); 
                console.log('trainer image : ', trainer.data.imageId);
               
                const touchPoints = training.data.defaultTouchPointsArray 
                
                //Calculate the sendat for scheduled jobs based on the start date of the instance : 
                const updatedTouchPoints = touchPoints.map((touchPoint) => {
                    
                    if(touchPoint.timingMode === 'Scheduled') {
                        const { beforeOrAfter, numberOfDays } = touchPoint;
                        const { hour, minute } = touchPoint.sendAt;

                        const daysDifference = beforeOrAfter === 'before' ? numberOfDays * -1 : numberOfDays;
                        const calculatedDate = instance ? new Date(moment(instance.startDate).add(daysDifference, 'days')) : moment().add(daysDifference, 'days');
                
                        const sendAt = {
                            minute : minute,
                            hour : hour,
                            day : parseInt(moment(calculatedDate).format('DD/MM/YYYY').split('/')[0]),
                            month : parseInt(moment(calculatedDate).format('DD/MM/YYYY').split('/')[1]),
                            year : parseInt(moment(calculatedDate).format('DD/MM/YYYY').split('/')[2])
                        }

                        return {
                            ...touchPoint,
                            sendAt
                        }
                    } else {
                        return touchPoint;
                    }

                })

                const signUpTriggers = updatedTouchPoints.filter(touchPoint => touchPoint.selectedTrigger === 'sign-up');
                const mailScheduledTasks = updatedTouchPoints.filter(touchPoint => touchPoint.timingMode === 'Scheduled');
                
                const signupMailInfo = signUpTriggers.length>0 ? {
                    to : data.email,
                    from : 'maxim.polderman@gmail.com', //For now, later change to trainer email 
                    subject : signUpTriggers[0].subject,
                    html : `<p>${signUpTriggers[0].content}</p>`
                } : null;

                const user = {
                    firstName : data.firstName.charAt(0).toUpperCase() + data.firstName.slice(1),
                    lastName : data.lastName.charAt(0).toUpperCase() + data.lastName.slice(1),
                    email : data.email,
                    company : data.company,
                    role : data.role,
                    paymentStatus : 'pending',
                    amountToPay : instance.price,
                    receivedSurvey : false,
                    mailsReceived : []
                }       

                const response = await Axios.post(`${backEndAdress}/api/instances/add-attendee` , {
                    instanceId : instance._id , 
                    attendee : user,
                    signupMailInfo : signupMailInfo,
                    mailScheduledTasks,
                    instance : instance,
                    handlebarsVariables : {
                        first_name : data.firstName.charAt(0).toUpperCase() + data.firstName.slice(1),
                        last_name : data.lastName.charAt(0).toUpperCase() + data.firstName.slice(1),

                        trainer_name : `${trainer.data.firstName} ${trainer.data.lastName}`,
                        trainer_email : trainer.data.email,
                        trainer_phone : trainer.data.phone,
                        trainer_image : trainer.data.imageId, 
                        trainer_description : trainer.data.shortDescription,
                        trainer_linkedin : trainer.data.linkedIn,

                        training_name : training.data.name,
                        short_description : training.data.description,
                        dates_concatted : instance.dates.map(date => moment(date).format('DD/MM/YYYY')).join(' , '),

                        instructions : location.data.instructions,
                        instructions_image_id : location.data.instructionsMapImageId
                    },
                    training : training.data,
                    tenant 
                })
                
                setState({...state , registeredConfirmation : true})
            } catch (error) {
                console.log(error);
            }
        }

        addAttendee();
    }

    const rendering = () => {
        return (
            <ReactFormSC>
                <>
                    <h1>Sign-up</h1>
                    <form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={2} width={400}>
                            <TextField
                                required
                                id="firstName"
                                label="First Name"
                                variant="outlined"
                                {...register('firstName',
                                    { required : 'first name is required' }
                                )}
                                error={!!errors.firstName}
                                helperText={errors.firstName?.message}
                            />
                            <TextField
                                required
                                id="lastName"
                                label="Last Name"
                                variant="outlined"
                                {...register('lastName',
                                    { 
                                        required : 'Last name is required'
                                 }
                                )}
                                error={!!errors.lastName}
                                helperText={errors.lastName?.message}
                            />
                            <TextField
                                required
                                id="email"
                                label="Email"
                                variant="outlined"
                                {...register('email', 
                                    { 
                                        required : 'Email is required', 
                                        pattern : {
                                            value : /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                            message : 'Not a valid email'
                                        }
                                    }
                                )}
                                error={!!errors.email}
                                helperText={errors.email?.message}
                            />
                            <TextField
                                required
                                id="company"
                                label="Company"
                                variant="outlined"
                                {...register('company',
                                    { required : 'Company is required' }
                                )}
                                error={!!errors.company}
                                helperText={errors.company?.message}
                            />
                            <TextField
                                required
                                id="role"
                                label="Role"
                                variant="outlined"
                                {...register('role',
                                    { required : 'Role is required' }
                                )}
                                error={!!errors.role}
                                helperText={errors.role?.message}

                            />
                            <Button variant="contained" color="primary" type="submit">Submit</Button>
                        </Stack>

                    </form>
                </>
            </ReactFormSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const ReactFormSC = styled.div`
    background-color : white;
    padding : 20px;
    max-width : 100%;
`

export default ReactForm