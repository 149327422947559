import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import DropdownWithTableModal from '../../../GeneralComponents/InputFields/DropDownWithTableModal';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';

const AvailableLocationsTable = ({ locations, setLocationsPopUp, state, setState, trainingToEditLocal, setTrainingToEditLocal }) => {

    const [localLocations, setLocalLocations] = React.useState(trainingToEditLocal.customProperties.availableLocations);

    const LocationComponent = (p) => {
        const { title, thumbnailImageUrl } = p.data;

        return (
            <div style={{ display : 'flex', alignItems : 'center', minHeight : '100%'}}>
                <img 
                    src={thumbnailImageUrl} 
                    alt='avatar' 
                    style={{ 
                        width : '35px', 
                        height : '35px', 
                        objectFit : 'cover',
                        borderRadius : '50%'
                    }}
                />
                <p style={{paddingLeft : '10px'}}>{title}</p>
            </div>
        );
    }

    const onSelectionChanged = (e) => {
        const selectedRows = e.api.getSelectedRows();
        setLocalLocations(selectedRows);
    }

    const saveLocations = () => {
        setTrainingToEditLocal({
            ...trainingToEditLocal,
            customProperties : {
                ...trainingToEditLocal.customProperties, 
                availableLocations : localLocations
            }
        });
        setLocationsPopUp(false);
    }


    const rendering = () => {

        return (
            <AvailableLocationsTableSC>
                <div className='table-header'>
                    <p>Available Locations</p>
                    <div className='buttons'>
                        <Button
                            variant='outlined'
                            color='primary'
                            onClick={() => setLocationsPopUp(false)}
                            style={{marginRight : '10px'}}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant='outlined'
                            color='primary'
                            onClick={() => saveLocations()}
                        >
                            Save
                        </Button>
                    </div>
                </div>
                <div className='parent-container'>
                    <div className='ag-theme-material' style={{height : "100%", width : "100%"}} >
                        <AgGridReact
                            rowData={locations} 
                            columnDefs={[
                                { valueGetter : p => p.data, cellRenderer : LocationComponent, headerName : 'Location', checkboxSelection : true, flex : 1 },
                                {field : 'adress', headerName : 'Adress', flex : 1},
                            ]}
                            rowSelection={'multiple'}
                            onSelectionChanged={(e) => onSelectionChanged(e)}
                            rowMultiSelectWithClick={true}
                            onGridReady={(params) => {                               
                                params.api.forEachNode((node) => {
                                    const shouldBeSelected = trainingToEditLocal.customProperties.availableLocations.map((loc) => loc._id).includes(node.data._id);
                                    // Pre-select rows where rowSelected is true
                                    if (shouldBeSelected) {
                                    node.setSelected(true);
                                    }
                                });
                            }}

                        />
                    </div>
                </div>

            </AvailableLocationsTableSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const AvailableLocationsTableSC = styled.div`
    min-height : 80vh;
    min-width : 800px;
    background-color : white;
    z-index : 100;
    display : flex;
    flex-direction : column;
    border-radius : 10px;
    box-shadow : 0px 0px 5px 0px rgba(0,0,0,0.2);
    overflow : hidden;

    .table-header {
        display : flex;
        justify-content : space-between;
        align-items : center;
        padding : 20px;
        border-bottom : 1px solid lightgrey;
    }

    .parent-container {
        flex-grow : 1;
        background-color : grey;
        height : 200px;
    }

    .ag-theme-material {
            --ag-font-size: inherit;
            --ag-font-family: inherit;    

        .ag-cell-focus {
            outline: none; /* Remove the default outline */
        }

        .ag-row:focus {
            outline: none; /* Remove the default row focus outline */
        }
    
    }


`

export default AvailableLocationsTable