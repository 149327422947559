import React from 'react';
import styled from 'styled-components';
import { MetricDashboardContext } from '../MetricDashboard';

const Revenue = () => {

    const { instances } = React.useContext(MetricDashboardContext);

    let EURO = new Intl.NumberFormat('sfb', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
    });
    
    let revenue = 0; 

    instances.forEach(instance => {
        instance.attendees.forEach(attendee => {    
            console.log('attendee : ', attendee);   
            revenue += attendee.amountToPay;
        });
    });

    const rendering = () => {
        return (
            <RevenueSC>
                <p className='kpi-label'>Revenue</p>
                <p className='kpi-value'>{EURO.format(revenue)}</p>
            </RevenueSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const RevenueSC = styled.div`
    background-color : white;
    padding : 15px;
    border-radius : 10px;
    margin : 15px;
    display : flex;
    flex-direction : column;
    min-width : 180px;

    .kpi-label {
        font-size : 0.8rem;
        color : grey;
    }

    .kpi-value {
        font-size : 1.5rem;
    }
`

export default Revenue