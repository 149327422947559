import React from 'react';
import styled from 'styled-components';
import { IndividualTrainingContext } from '../../IndividualTraining';

const InstanceTrainer = () => {

    const { trainer } = React.useContext(IndividualTrainingContext);

    const rendering = () => {
        return trainer &&  (
            <InstanceTrainerSC>
                <div className='avatar' style={{ backgroundImage : `url(${trainer.imageUrl})`}}>
                </div>
                <div className='location-information'>
                    <p className='location-title title'>{trainer.firstName} {trainer.lastName}</p>
                    <p className='locationsub-info'>{trainer.email}</p>
                    <p className='locationsub-info'>{trainer.phone}</p>
                </div>
            </InstanceTrainerSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const InstanceTrainerSC = styled.div`
    width : 100%;
    padding : 15px;
    box-sizing : border-box;
    background-color : white;
    border-radius : 5px;
    margin-top : 10px;
    box-shadow : 0px 0px 5px 0px rgba(0,0,0,0.1);
    display : flex;

    .avatar {
        min-height : 100px; 
        max-height : 100px;
        min-width : 100px;
        max-width : 100px;
        background-size : cover;
        background-position : center;
        border-radius : 5px;
    }

    .location-information {
        display : flex; 
        flex-direction : column;
        padding : 15px;
        padding-top : 0;
        padding-left : 20px;

        >.locationsub-info {
            color : grey;

        }
    }

    .title {
        font-size : 1.2em;
    }
`

export default InstanceTrainer