import React from 'react';
import styled from 'styled-components';
import InstancePicker from './InstancePicker';
import TrainingHeader from './TrainingHeader';
import TrainingProperties from './TrainingProperties';
import TrainingDescription from './Description';
import Attendees from './Attendees';
import TrainingOptions from './TrainingOptions';
import { useLocation } from 'react-router-dom';
import Axios from 'axios';
import { backEndAdress } from '../../variables/back-endAdress';
import EmailSchedule from '../emailSchedule/EmailSchedule';
import { Button } from '@mui/material';
import { get, set } from 'react-hook-form';
import { TrainingsOverViewContext } from '../TrainingsOverview/TrainingsOverview';
import SurveyMenu from './SurveyMenu';
import MailSchedule from '../MailScheduleComponent/MailSchedule';
import SendOutSurveyModal from './SendOutSurveyModal/SendOutSurveyModal';
import SureyOptionsButton from './SurveyOptionsButton';
import EmailScheduleButton from './EmailScheduleButton';
import SubscribeButton from './SubscribeButton';
import Modal from '../GeneralComponents/Modal';
import IndividualAttendee from '../individualAttendee/IndividualAttendee';
import { AppContext } from '../../App';
import JobsList from '../mailTraffic/jobsList/JobsList';
import JobsListComponent from './jobsList/JobsListComponent';
import ToggleJobsButton from './ToggleJobsButton';
import AddOrEditTraining from '../addOrEditTrainingComponent/AddOrEditTraining';

export const IndividualTrainingContext = React.createContext(null);

const IndividualTraining = ({ instance }) => {

    const { appState } = React.useContext(AppContext);
    const { tenant } = appState;
    const [backgroundImage, setBackGroundImage] = React.useState(null);
    const [emailTemplates, setEmailTemplates] = React.useState([]);

    const location = useLocation(); 
    const inputRef = React.useRef(null);
    const trainerRef = React.useRef(null);
    const priceRef = React.useRef(null);
    const locationRef = React.useRef(null);
    const difficultyRef = React.useRef(null);
    const languageRef = React.useRef(null);
    const formatRef = React.useRef(null);
    const typeRef = React.useRef(null); 
    const touchPointsRef = React.useRef(null);

    const [state, setState] = React.useState({
        currentInstance : null,
        emailScheduleToggled : false,
        editMode : false,
        localInstanceInEditMode : null,
        currentTraining : null,
        surveyMenu : false,
        touchPointsFromChild : [],
        sendOutSurveyModalToggled : false,
        selectedForSurvey : [],
        sendSurveyLoading : false,
        individualAttendee : null,
        jobsListToggled : false,
        trainingToEdit : null
    });

    const [training, setTraining] = React.useState(null);
    const [trainingLocation, setTrainingLocation] = React.useState(null);   
    const [trainer, setTrainer] = React.useState(null);
    const [mailJobs, setMailJobs] = React.useState([]);
    const [updating, setUpdating] = React.useState(false);

    React.useEffect(() => {
        const instanceIdFromURL = window.location.hash.split('/')[2];

        const getInstance = async () => {
            try {
                //get one by ID
                const response = await Axios.get(`${backEndAdress}/api/instances/${instanceIdFromURL}`);
                console.log('response from fetch isntances :', response.data);
                setState({
                    ...state,
                    currentInstance : response.data
                }); 
            } catch (error) {
                console.log(error);
            }
        }

        if(instanceIdFromURL) {
            getInstance();
        }

    }, [location]);

    React.useEffect(() => {

        const getTraining = async () => {
            try {
                const test = await Axios.get(`${backEndAdress}/api/trainings/${state.currentInstance.trainingId}`);
                setTraining(test.data);
            } catch (error) {
                console.log(error);
            }
        }

        const getLocation = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/locations/${state.currentInstance.location._id}`);
                setTrainingLocation(response.data);
            } catch (error) {
                console.log(error);
            }
        }

        const getTrainer = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/trainers/${state.currentInstance.trainer._id}`);
                setTrainer(response.data);
            } catch (error) {
                console.log(error);
            }
        }

        const getMailJobs = async () => {
            try {
                const mailJobs = await Axios.get(`${backEndAdress}/api/mail-jobs/for-instance/${state.currentInstance._id}`);
                setMailJobs(mailJobs.data);
            } catch (error) {
                console.log('error : ', error);
            }
        }

        if(state.currentInstance) {
            getTraining();
            getLocation();
            getTrainer(); 
            getMailJobs();
        }

    }, [state.currentInstance]);
   
    React.useEffect(() => {

        const saveInstance = async () => {
            try {
                const response = await Axios.post(`${backEndAdress}/api/instances/update-instance/${state.currentInstance._id}`, state.currentInstance);
            } catch (error) {
                console.log(error);
            }
        }

        if(state.currentInstance) {
            saveInstance();
        }
    }, [state.currentInstance]);

    React.useEffect(() => {
        if(state.editMode) {
            setState({
                ...state,
                localInstanceInEditMode : state.currentInstance
            });
        }
    }, [state.editMode])

    React.useEffect(() => {
        const getEmailTemplates = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/mail-templates/find-all/Credon`);
                setEmailTemplates(response.data);
            } catch (error) {
                console.log(error);
            }
        }

        getEmailTemplates();

    },[]);

    const saveInstance = () => {

        const newInstance = {
            ...state.currentInstance,
            instanceName : inputRef.current.value,
            trainer : trainerRef.current,
            price : parseInt(priceRef.current.value),
            location : locationRef.current,
            difficulty : difficultyRef.current.value,
            language : languageRef.current.value,
            format : formatRef.current.value,
            type : typeRef.current.value,
        }

        const updateInstance = async () => {
            try {
                const response = await Axios.post(`${backEndAdress}/api/instances/update-instance/${state.currentInstance._id}`, newInstance);
                setState({
                    ...state,
                    currentInstance : response.data,
                    editMode : false
                });
            } catch (error) {
                console.log(error);
            }
        };

        updateInstance();        
    }

    const saveTouchPointsToInstance = () => {
        setState({
            ...state,
            currentInstance : {
                ...state.currentInstance,
                touchPoints : state.touchPointsFromChild
            },
            emailScheduleToggled : false
        });
    }

    const divRef = React.useRef(null);

    React.useEffect(() => {

        if(state.currentInstance) {

            const handleClickOutside = (event) => {
                if (divRef.current && !divRef.current.contains(event.target)) {
                  console.log('clicked outside');
                  setState({...state, surveyMenu : false});
                }
              };
          
              document.addEventListener('mousedown', handleClickOutside);

              return () => {
                document.removeEventListener('mousedown', handleClickOutside);
              };
        }

   

      }, [state.currentInstance]);


    const sendOutSurvey = () => {

        const sendOutSurveyFunction = async () => {

            setState({...state, sendSurveyLoading : true});

            try {
                // For each attendee in the list perform the sendSurveysFunction
                const promises = state.selectedForSurvey.map(async (attendeeId) => {

                    const surveyLink = `http://localhost:3000/#/fill-survey/${training.surveyId}/${state.currentInstance._id}/${attendeeId._id}`;
            
                    const handleBars = {
                        first_name: attendeeId.firstName.charAt(0).toUpperCase() + attendeeId.firstName.slice(1),
                        survey_link: surveyLink
                    };

                    const sendSurveysFunction = async () => {
                        try {
                            const response = await Axios.post(`${backEndAdress}/api/instances/send-out-surveys`, { 
                                handleBars: handleBars, 
                                attendee: attendeeId,
                                instance: state.currentInstance,                        
                            });
                            return response; 
                        } catch (error) {
                            return error;
                        }
                    };

                    // Return the promise so we can wait for it
                    return sendSurveysFunction();
                });
        
            // Wait for all promises to complete
            await Promise.all(promises);
            
            const updatedInstance = await Axios.get(`${backEndAdress}/api/instances/${state.currentInstance._id}`);
            setState({...state, currentInstance: updatedInstance.data, selectedForSurvey: [], sendSurveyLoading: false});
        

        
            } catch (error) {
                console.error('Error processing surveys:', error);
            }
        }
    
        sendOutSurveyFunction();

    };

    const rendering = () => {

        if(state.currentInstance === null || training === null ) return (<div>Loading...</div>);
        if(appState.tenant !== training.tenant) return (<div>Not authorized</div>);
        
        return (
            appState.tenant === training.tenant &&
            <IndividualTrainingContext.Provider value={{
                state,
                setState,
                inputRef,
                trainerRef,
                priceRef,
                locationRef,
                difficultyRef,
                languageRef,
                formatRef,
                typeRef,
                saveInstance,
                training,
                setTraining,
                sendOutSurvey,
                trainingLocation,
                trainer
            }}>

                <IndividualTrainingSC>
                    <>
                        {state.trainingToEdit && 
                            <AddOrEditTraining
                                appState={appState}
                                state={state}
                                setState={setState}
                                trainingToEdit={state.trainingToEdit}
                                closeWindowFunction={() => setState({...state, trainingToEdit : null})}
                                setTraining={setTraining}
                                setMailJobs={setMailJobs}
                            />
                        }

                    
                        {state.individualAttendee && 
                            <IndividualAttendee 
                                attendee={state.individualAttendee}
                                state={state}
                            />
                        }

                        {state.jobsListToggled &&
                            <JobsListComponent
                                mailJobs={mailJobs}
                                scheduledJobs={state.currentInstance.scheduledJobs}
                                setState={setState}
                                state={state}
                            />
                        }

                        {state.emailScheduleToggled && 
                        <Modal>
                            <div className='mail-schedule-container'>
                                <div className='wrapper'>
                                    <div className='top-wrapper'>
                                        <p className='title-mail-schedule'>Mail schedule</p>
                                        <div className='buttons-topper'>
                                            <Button
                                                variant='outlined'
                                                onClick={() => saveTouchPointsToInstance()}
                                            >   
                                                save
                                            </Button>
                                            <Button
                                                variant='outlined'
                                                onClick={() => setState({...state, emailScheduleToggled : false  })}
                                            >   
                                                close
                                            </Button>
                                        </div>
                                    </div>
                                    <div className='mail-schedule-content'>
                                        <MailSchedule
                                            emailTemplates={emailTemplates}
                                            defaultTouchPoints={state.currentInstance.touchPoints}
                                            state={state}
                                            setState={setState}
                                            touchPointsRef={touchPointsRef}
                                            training={training}
                                            trainingLocation={trainingLocation}
                                            trainer={trainer}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Modal>
                        }

                        {state.sendOutSurveyModalToggled && 
                            <SendOutSurveyModal
                                instance={state.currentInstance}
                                state={state}
                                setState={setState}
                            />
                        }
                    </>

                    <div className='content-fields'>
                        <div className='left-panel'>
                            <InstancePicker trainingIdFromURL={window.location.hash.split('/')[3]}/>
                            <TrainingHeader />
                            <TrainingProperties />
                        </div>

                        <div className='right-panel-main'>
                            <div className='options-row'>
                                <SureyOptionsButton 
                                    training={training}
                                    divRef={divRef}   
                                />
                                <ToggleJobsButton
                                    state={state}
                                    setState={setState}
                                />

                                {/* <EmailScheduleButton /> */}
                                <SubscribeButton />
                                {/* <button
                                    onClick={() => setState({...state, trainingToEdit : training})}
                                >
                                    edit training
                                </button> */}
                            </div>
                            {/* <TrainingOptions state={state} setState={setState} training={training}/> */}
                            <Attendees />
                        </div>
                    </div>

                </IndividualTrainingSC>

            </IndividualTrainingContext.Provider>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const IndividualTrainingSC = styled.div`
    flex-grow : 1;
    display : flex;
    min-height : 100%;
    flex-direction : column;

    .mail-schedule-container {
        background-color : rgba(0,0,0,0.1);
        position : absolute;
        top : 0; 
        right : 0;
        bottom : 0;
        left : 0;
        display : flex;
        justify-content : center;
        align-items : center;
        z-index : 1000;
        overflow : hidden;

        .wrapper {
            min-height : 90vh;
            min-width : 90vw;
            background-color : white;
            border-radius : 5px;
            overflow : hidden;
            position : relative;
            display : flex;
            flex-direction : column;

            .top-wrapper {
                display : flex;
                justify-content : space-between;
                overflow : hidden;

                .buttons-topper {
                    background-color : white;
                    display : flex;
                    align-items : flex-end;
                    justify-content : flex-end;
                    height : 80px;
                    overflow-y : auto;
                    box-sizing : border-box;
                    align-items : center;
                    margin-right : 20px;

                    &>:first-child {
                        margin-right : 10px;
                    }
                }

                .title-mail-schedule {
                    display : flex;
                    padding-left : 20px;
                    justify-content : center;
                    align-items : center;

                }
            }
            


            .mail-schedule-content {
                display : flex;
                flex-direction : column;
                flex-grow : 1;
                overflow-y : scroll;
            }
        }

    }

    .top-bar {
        background-color : white;
        display : flex;
        align-items : center;
        padding : 10px;
        margin : 15px;
        margin-bottom : 0;
        border-radius : 5px;
        box-shadow : 0px 0px 5px 0px rgba(0,0,0,0.1);
    }

    .content-fields {
        display : flex;
        flex-grow : 1;
        padding : 10px;
        padding-top : 0;
        
        .left-panel {
            padding : 10px;
            flex-grow : 1;
            display : flex;
            flex-direction : column;
            max-width : 50%;
        }

        .right-panel-main {
            width : 50%;

            .options-row {
                display : flex;
            }
        }
    }


`

export default IndividualTraining