import React from 'react';
import styled from 'styled-components';
import NumberOfAttendees from './NumberOfAttendees';
import Revenue from './Revenue';
import CardObject from './CardObject';
import { MetricDashboardContext } from '../MetricDashboard';
import BarchartObject from './BarchartObject';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PaidIcon from '@mui/icons-material/Paid';

const DashboardObjects = () => {

    const { instances } = React.useContext(MetricDashboardContext);

    let EURO = new Intl.NumberFormat('sfb', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0
    });
    
    let revenue = 0; 

    instances.forEach(instance => {
        instance.attendees.forEach(attendee => {    
            console.log('attendee : ', attendee);   
            revenue += attendee.amountToPay;
        });
    });

    const numberOfAttendees = instances.reduce((acc, instance) => {
        return acc + instance.attendees.length;
    }, 0);


    const rendering = () => {
        return (
            <DashboardObjectsSC>
                <div className='row-item-dashoard'>
                    <CardObject 
                        title={'Attendees'}
                        value={numberOfAttendees}
                        image={'./images/attendees.png'}
                        subTitle={'+4 vs last month'}
                        imageUrl={'https://cdn.dribbble.com/users/7356472/screenshots/15388895/flat-design-avatar-beard-face_4x.jpg'}
                        icon={<PeopleAltIcon/>}
                        backgroundColor={'#26b3eb'}
                    />
                    <CardObject 
                        title={'Revenue'}
                        value={EURO.format(revenue)}
                        imageUrl={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyXFJsqT_jXLTWxEsiqvgo8NlAhryaixfeBg&s'}
                        subTitle={'+4 vs last month'}
                        icon={<PaidIcon/>}
                        backgroundColor={'#26b3eb'}
                    />
                </div>
                {/* <div className='row-item-dashoard'>
                    <BarchartObject />
                </div> */}
            </DashboardObjectsSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const DashboardObjectsSC = styled.div`
    display : flex;
    flex-direction : column;
    padding : 15px;
    .row-item-dashoard {
        display : flex;
    }
`

export default DashboardObjects