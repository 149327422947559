import React from 'react';
import styled from 'styled-components';
import AddInstanceNavigation from './addInstanceNavigation/AddInstanceNavigation'; 
import AddInstanceFooter from './addInstanceFooter/AddInstanceFooter';
import AddInstanceDates from './addInstanceDates/AddInstanceDates';
import ReviewAndSubmit from './reviewAndSubmit/ReviewAndSubmit';
import AddInstanceProperties from './addInstanceProperties/AddInstanceProperties';
import Modal from '../GeneralComponents/Modal';
import { colors } from '../../variables/Colors';
import Axios from 'axios'; 
import { backEndAdress } from '../../variables/back-endAdress';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

export const AddInstanceContext = React.createContext();

const AddInstance = ({ state, setState }) => {

    const formRef = React.useRef(null);

    const navigate = useNavigate();
    const { preOpenTraing } = state;
    const form = useForm();
    const { register, handleSubmit, watch, trigger, control, formState } = form;
    const { errors, isValidating } = formState;

    const [currentMenu, setCurrentMenu] = React.useState(1);
    const [dates, setDates] = React.useState([]);
    const [trainer, setTrainer] = React.useState(null);
    const [location, setLocation] = React.useState(null);
    const [price, setPrice] = React.useState(null);
    const [language, setLanguage] = React.useState(null);

    // Closing the add instance menu 
    const closeAddInstance = () => {
        setState({...state, addInstanceToggled : false});
    }

    // Saving the instance
    const submitIt = (data) => {

        const { capacity } = data;

        const startDate = dates[0];
        const trainingId = preOpenTraing._id; 
        const trainingName = preOpenTraing.name;
        const touchPoints =  preOpenTraing.defaultTouchPointsArray;

        const instanceName = 'testing';
        
        // const format = document.getElementById('Format').value;

        const handleBars = {
            userName : '[userName]',
            trainingName,
            trainingDescription : preOpenTraing.description,
            adress : location ? location.adress : '',
            locationInstructions : location ? location.instructions : '[locationInstructions]',
            instanceLanguage : language,
            trainerName : trainer ? `${trainer.firstName} ${trainer.lastName}` : '[trainerName]',
            trainerDescription : trainer ? trainer.shortDescription : '[trainerDescription]',
            trainerImageURL : 'trainerImageURL',
        }

        const instance = {
            trainingId,
            trainingName, 
            instanceName : 'instance name',
            trainer,
            price,
            location,
            language,
            capacity,
            startDate,
            trainingDates : [dates],
            attendees : [],
            dates,
            handleBars,
            touchPoints
        };

        const submitFunction = async () => {
            try {
                const response = await Axios.post(`${backEndAdress}/api/instances`, instance);
                navigate(`/training/${response.data._id}/${trainingId}`);
            } catch (error) {
                console.log('error : ', error);
            }
        }        
        
        submitFunction();
       
    }

    const rendering = () => {
        return (
            <AddInstanceContext.Provider value={{
                currentMenu, 
                setCurrentMenu,
                dates,
                setDates,
                trainer,
                setTrainer,
                location,
                setLocation,
                price, 
                setPrice,
                language,
                setLanguage,
                saveFunction : submitIt,
                register, 
                errors, 
                form,
                formRef
            }}>
                <Modal>
                    <AddInstanceSC>
                        <AddInstanceNavigation/>
                        <form noValidate onSubmit={handleSubmit(submitIt)} ref={formRef}>
                            <div className='content'>
                                {currentMenu === 1 && <AddInstanceDates />}
                                {currentMenu === 2 &&  <AddInstanceProperties />}
                            </div>
                        </form>
                        <AddInstanceFooter closeFunction={closeAddInstance} />
                    </AddInstanceSC>
                </Modal>
            </AddInstanceContext.Provider>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const AddInstanceSC = styled.div`
    min-width : 350px;
    width : 80vw;
    min-height : 500px;
    height : 80vh;
    background-color : white;
    border-radius : 5px;
    display : flex;
    flex-direction : column;
    box-sizing : border-box;
    position : relative; 
    overflow : hidden;
    box-shadow : 0px 0px 5px 0px ${colors.lightGrey};

    form {
        width : 100%;
        flex-grow : 1;
        display : flex;
        flex-direction : column;
    }

    .content {
        flex-grow : 1;
        display : flex; 
        overflow : hidden;
    }
`

export default AddInstance