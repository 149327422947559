import React from 'react';
import Header from './header/Header';
import MainContent from './mainContent/MainContent';
import Footer from './footer/Footer';
import Modal from '../GeneralComponents/Modal';
import styled from 'styled-components';
import WebIcon from '@mui/icons-material/Web';
import { useForm } from 'react-hook-form';
import { DevTool } from '@hookform/devtools';
import TextFormInput from '../GeneralComponents/formInputs/TextFormInput';
import { AppContext } from '../../App';
import Axios from 'axios'; 
import { backEndAdress } from '../../variables/back-endAdress'; 
import { defaultTouchPointsArray } from '../addTraining/defaultTouchPointsArray';
import OnCompleteAddTrainingMenu from '../addTraining/OnCompleteAddingTrainingMenu';
export const AddTrainingContext = React.createContext(null);

const IndividualTraining = ({ closeFunction }) => {

    const { appState } = React.useContext(AppContext);
    const { tenant } = appState;
    const form = useForm();
    const { register, handleSubmit, watch, trigger, control, formState } = form;
    const { errors, isValidating } = formState;
    const formValues = watch(); 

    const [activeItem, setActiveItem] = React.useState(0);
    const [surveys, setSurveys] = React.useState([]);
    const [mailTemplates, setMailTemplates] = React.useState([]);
    const [imageURL, setImageURL] = React.useState('https://previews.123rf.com/images/sodis/sodis1804/sodis180400047/100084305-business-training-flat-design-vector-illustration.jpg');
    const [file, setFile] = React.useState(null);

    const formRef = React.useRef(null);

    const [state, setState] = React.useState({
        selectedCertificateTemplate : null,
        certificateTemplates : [],
        selectedSurvey : null,
        touchPoints : [],
        completeTrainingMenu : false,
        savedTrainingResponse : null,
        touchPointsFromChild: [],
        trainingCreated : '',
    });

    const navigationItems = [{
        title: 'Training',
        icon : <WebIcon />
    },{
        title : 'Website',
        icon : <WebIcon />
    },{
        title : 'Mailschedule',
        icon : <WebIcon />
    },{
        title : 'Feedback',
        icon : <WebIcon />
    },{
        title : 'Certificate',
        icon : <WebIcon />
    },{
        title : 'Review',
        icon : <WebIcon />
    }];

    // Fetching data on component render 
    React.useLayoutEffect(() => {
        const getEmailTemplates = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/mail-templates/find-all/${tenant}`);
                setMailTemplates(response.data);
            } catch (error) {
                console.log(error);
            }
        }
        
        const getCertificateTemplates = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/certificate-template/get-all/${tenant}`);
                setState({...state, certificateTemplates : response.data});
            } catch (error) {
                console.log(error);
            }
        }

        const getSurveys = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/surveys`);
                setSurveys(response.data);
            } catch (error) {
                console.log(error);
            }
        }

        getCertificateTemplates();
        getEmailTemplates();
        getSurveys();


    },[]);

    // Save or edit training
    const onSubmit = (data) => {

        setState({...state, trainingCreated : data.title});
        
        const training = {
            name : data.title,
            description : data.description,
            certificateTemplateId : state.selectedCertificateTemplate._id,
            surveyId : state.selectedSurvey._id,
            defaultTouchPointsArray : state.touchPointsFromChild,
            tenant
        };

       
        const formData = new FormData();
        formData.append('s3Image', file);
        formData.append('training', JSON.stringify(training));

        const saveTraining = async () => {
            try {
                const response = await Axios.post(`${backEndAdress}/api/trainings`, formData , { headers : { 'Content-Type' : 'multipart/form-data' } });
                setState({
                    ...state, 
                    completeTrainingMenu : true,
                    savedTrainingResponse : response.data              
                });
            } catch (error) {
                console.log('error', error);
            }
        }

        saveTraining();

    }

    return (

        <Modal>
            <AddTrainingSC>
                <AddTrainingContext.Provider value={{ 
                    navigationItems,
                    activeItem,
                    setActiveItem,
                    formRef
                }}>
                    
                    { state.completeTrainingMenu && 
                        <OnCompleteAddTrainingMenu 
                            trainingCreated={state.trainingCreated}
                            savedTrainingResponse={state.savedTrainingResponse}    
                        /> 
                    }

                    <Header />
                    <form 
                        onSubmit={handleSubmit(onSubmit)} 
                        noValidate
                        ref={formRef} 
                    >
                        <MainContent 
                            register={register}
                            errors={errors}   
                            surveys={surveys}
                            mailTemplates={mailTemplates}
                            state={state}
                            setState={setState} 
                            defaultTouchPoints={defaultTouchPointsArray}
                            formValues={formValues}
                            trigger={trigger}
                            imageURL={imageURL}
                            setImageURL={setImageURL}
                            file={file}
                            setFile={setFile}
                            activeItem={activeItem}
                        />
                    </form>
                    <DevTool control={control}/>
                    <Footer 
                        activeItem={activeItem}
                        setActiveItem={setActiveItem}
                        navigationItems={navigationItems}
                        trigger={trigger}
                        
                    />
                </AddTrainingContext.Provider>
            </AddTrainingSC>
        </Modal>
    );
};

const AddTrainingSC = styled.div`
    min-height : 95vh; 
    min-width : 95vw; 
    max-height : 95vh;
    max-width : 95vw;
    display : flex;
    flex-direction : column;
    background-color : white;
    border-radius : 10px;
    box-shadow : 0 0 10px 0 rgba(0,0,0,0.1);
    position : relative;
    overflow : hidden;

    &>form {
        display : flex;
        flex-direction : column;
        flex-grow : 1;
        overflow : hidden;
    }
`

export default IndividualTraining;