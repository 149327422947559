import React from 'react';
import styled from 'styled-components';
import SurveyItems from './SurveyItems';
import SurveyButtons from './SurveyButtons';
import Axios from 'axios';
import { backEndAdress } from '../../../variables/back-endAdress';
import PreSendMenu from './PreSendMenu';
import SurveyHeader from './SurveyHeader';

export const SurveyContext = React.createContext(null);

const Survey = () => {

    const instanceIdFromURL = window.location.hash.split('/')[3];
    const surveyIdFromUrl = window.location.hash.split('/')[2];
    const userIdFromUrl = window.location.hash.split('/')[4];

    const firstNameRef = React.useRef(null);
    const lastNameRef = React.useRef(null);
    const emailRef = React.useRef(null);
  
    const [state, setState] = React.useState({
        survey : null,
        training : null,
        instance : null,
        preSendToggled : false,
        sendingSurveyLoading : false,
        certCreated : null
    });

    const [training, setTraining] = React.useState(null); 
    const [instance, setInstance] = React.useState(null);
    const [trainer, setTrainer] = React.useState(null);
    const [answers, setAnswers] = React.useState([]);
    const [user, setUser] = React.useState(null);
    const [certificateTemplate, setCertificateTemplate] = React.useState(null);

    //All use effects
    {
    React.useEffect(() => {
        const getSurvey = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/surveys/${surveyIdFromUrl}`)
                setState({
                    ...state,
                    survey : response.data,
                })
            } catch (error) {
                console.log(error)
            }
        }

        getSurvey()

    },[]);

    React.useEffect(() => {
        const getInstance = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/instances/${instanceIdFromURL}`)
                setInstance(response.data);
            } catch (error) {
                console.log(error)
            }
        }

        getInstance()

    },[]);

    React.useEffect(() => {
        const getTrainer = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/trainers/${instance.trainer._id}`)
                setTrainer(response.data);
            } catch (error) {
                console.log(error)
            }
        }
        if(instance) {
            getTrainer()
        }

    },[instance]);

    React.useEffect(() => {
        if(instance) {
            const user = instance.attendees.filter((user) => user._id == userIdFromUrl)[0];
            setUser(user);
        }

    },[instance]);

    React.useEffect(() => {
        const getTraining = async () => {
            try {
                const test = await Axios.get(`${backEndAdress}/api/trainings/${instance.trainingId}`);
                setTraining(test.data);
            } catch (error) {
                console.log(error);
            }
        }

        if(instance) {
            getTraining()
        }

    },[instance]);

    React.useEffect(() => {
        const getCertificateTemplate = async () => {
            try {
                const test = await Axios.get(`${backEndAdress}/api/certificate-template/${training.certificateTemplateId}`);
                setCertificateTemplate(test.data);
            } catch (error) {
                console.log(error);
            }
        }

        if(training) {
            getCertificateTemplate()
        }

    },[training]);
    }

    const sendSurvey = () => {

        setState({...state, sendingSurveyLoading : true});

        const freeTextAnswers = []; 
        const freeTextItems = state.survey.surveyItems.filter(item => item.type === 'free-text');

        freeTextItems.forEach(item => {
            const responseObject = {
                question : item.question,
                possibleAnswers : item.options,
                type : item.type,
                answerIndex : document.getElementById(item.question).value
            }

            freeTextAnswers.push(responseObject);
        });

        const finalResponses = answers.concat(freeTextAnswers);

        const answersObject = {
            surveyId : surveyIdFromUrl,
            training,
            instance,
            trainer,
            user : 'loserke',
            responses : finalResponses
        }


        const createAnswersObject = async () => {
            try {
                const response = await Axios.post(`${backEndAdress}/api/survey-responses`, answersObject)
                console.log('succes', response.data);
            } catch (error) {
                console.log(error)
            }
        }

        const createCertificate = async () => {
            const certificateObject = {
                image : certificateTemplate.imageId,
                trainingName : training.name,
                certificateName : certificateTemplate.certificateName,
                certificateDate : new Date(),
                user : {
                    userEmail : user.email,
                    firstName : user.firstName.charAt(0).toUpperCase() + user.firstName.slice(1),
                    lastName : user.lastName.charAt(0).toUpperCase() + user.lastName.slice(1)
                }
            }


            try {
                const response = await Axios.post(`${backEndAdress}/api/certificates/create-and-send`, certificateObject )
                return response.data;
            } catch (error) {
                console.log(error)
            }
        }

        const runFunction = async () => {
            await createAnswersObject();
            const certificate = await createCertificate(); 
            setState({
                ...state, 
                sendingSurveyLoading : false,
                certCreated : certificate
            
            });
        }

        runFunction();

    }

    const rendering = () => {
        if(training && instance && trainer ) {
            return ( 
                <SurveySC>
                    <SurveyContext.Provider value={{
                        state,
                        setState,
                        training,
                        instance,
                        trainer,
                        firstNameRef,
                        lastNameRef,
                        emailRef,
                        sendSurvey,
                        surveyIdFromUrl,
                        answers,
                        setAnswers,
                        user
                    }}>
                    <>
                        {state.preSendToggled && <PreSendMenu />}                               
                            <div className='survey-container'>
                                <SurveyHeader />
                                <SurveyItems />
                                <SurveyButtons />
                            </div>
                    </>
                    </SurveyContext.Provider>
                </SurveySC>
            );
        }
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SurveySC = styled.div`
    min-height : 100vh;
    min-width : 100vw;
    background-color : white;
    background-color : #f5f5f5;
    display : flex;
    flex-direction : column;
    box-sizing : border-box;
    
    .survey-options {
        display : flex;
        justify-content : space-between;
        padding : 10px;
        background-color : white;
        border-bottom : 1px solid #e0e0e0;
        padding : 15px;
        
        .other-options {
            display : flex;
            gap : 15px;
        }
        &>p {
            padding : 10px;
            cursor : pointer;
        }
    }
    .survey-container {
        display : flex;
        flex-direction : column;
        gap : 20px;
        width : 100%;
    }

`

export default Survey