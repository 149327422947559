import { AgGridReact } from 'ag-grid-react';
import React from 'react';
import styled from 'styled-components';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import moment from 'moment';
import DangerousIcon from '@mui/icons-material/Dangerous';
import Axios from 'axios'; 
import { backEndAdress } from '../../../variables/back-endAdress';

const JobsList = ({ mailJobs, selectJob }) => {


    const [scheduledJobs, setScheduledJobs] = React.useState([]);

    React.useEffect(() => {
        const scheduledJobs = async () => {
            try {
                const scheduledJobs = await Axios.get(`${backEndAdress}/api/mail-jobs/scheduled-jobs`);
                setScheduledJobs(scheduledJobs.data);
            } catch (error) {
                console.log('error : ', error);
            }
        }

        scheduledJobs();
    },[mailJobs]);

    const IsSentComponent = (p) => {
        return (
            <div className={`status-component ${p.value.hasRun ? 'status-true' : 'status-false'}`}>
                <p>{p.value.hasRun ? 'sent' : 'not sent'}</p>
            </div>
        )
    }

    const TrainingComponent = (p) => {
        const { trainingName, trainingThumbnail } = p.data;

        return (
            <div style={{ display : 'flex', alignItems : 'center', minHeight : '100%'}}>
                <p style={{paddingLeft : '10px'}}>{`${trainingName}`}</p>
            </div>
        );
    }      
    
    const ErrorComponent = (p) => {
        const isScheduled = scheduledJobs.includes(p.value.mailJobId);
        const { sendAt } = p.value;
        const now = new Date();


        if (!isScheduled && new Date(sendAt) >= new Date(now)) {
            return (
                <div className={`error-component`}>
                    <DangerousIcon style={{color : '#bf312c'}}/>
                </div>
            )
        } 
    }

    const colDefs=[
        { valueGetter : p => p.data, cellRenderer : ErrorComponent, headerName : ' ', flex : 1 },
        { field: "sendAt", headerName : 'Send at', valueFormatter : p => moment(new Date(p.value)).format('DD/MM/YYYY HH:mm'), flex : 2},
        { field: "subject", flex : 5 },
        { field: "sendTo", headerName : 'Send to', flex : 4 },
        { valueGetter : p => p.data, cellRenderer : IsSentComponent, headerName : 'Sent', flex : 2 },
    ];

    const rendering = () => {
        return (
            <JobsListSC>
                <div className='ag-theme-material' style={{ height: 400, width: 600 }}>
                <AgGridReact
                        style={{ width: '100%', height: '100%' }}
                        rowData={mailJobs} 
                        columnDefs={colDefs}
                        onRowClicked={(e) => selectJob(e.data)}
                    />
                </div>
            </JobsListSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const JobsListSC = styled.div`
    flex-grow : 1;
    display : flex; 
    flex-direction : column;


    .ag-theme-material {
        min-width : 100%;
        flex-grow : 1;

        --ag-font-size: inherit;
        --ag-font-family: inherit;    
        --ag-border-radius : 10px;
        --ag-font-size: inherit;
        --ag-font-family: inherit;    
        --ag-display : flex;
        --ag-flex-direction : column;

        .status-component {
            display : flex;
            align-items : center;
            min-height : 100%;

            &.status-true {
                &>p {
                    background-color : #5cff5c;
                }
            }

            &.status-false {
                &>p {
                    background-color : #b8b8b8;
                }
            }

            &>p {
                box-sizing : border-box;
                background-color : #ff5c5c;
                max-height : 27px;
                min-height : 27px;
                text-align : center;
                color : white;
                padding-top : 8px;
                padding-left : 15px;
                padding-right : 15px;
                margin : 0; 
                line-height : 1em;
                border-radius : 10px;
                box-sizing : border-box;
                font-size : 0.8em;


                

            }

        }

        .error-component {
            display : flex;
            align-items : center;
            min-height : 100%;
        }
    }
`

export default JobsList