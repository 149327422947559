import React from 'react';
import styled from 'styled-components';
import IndividualTouchPointTimingOptions from './IndividualTouchPointTimingOptions';
import { IndividualTouchPointContext } from '../IndividualTouchPoint';
import TimingModeTrigger from './TimingModeTrigger';
import TimingModeScheduled from './TimingModeScheduled';
import { IconButton, Button } from '@mui/material';
import { DeleteForever } from '@mui/icons-material';


const IndividualTouchPointTiming = () => {

    const { timingMode, removeTouchPoint, mailScheduleState } = React.useContext(IndividualTouchPointContext);

    const rendering = () => {
               
        return (
            <IndividualTouchPointTimingSC>
                <IndividualTouchPointTimingOptions />
 
                <div className='content'>
                    { timingMode === 'Trigger' && <TimingModeTrigger /> }
                    { timingMode === 'Scheduled' && <TimingModeScheduled /> }
                </div>
                <IconButton
                    className='delete-touchpoint-button'
                >
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => removeTouchPoint(mailScheduleState.individualTouchPoint.touchPointId)}
                    >
                        delete
                        <DeleteForever />
                    </Button>
                </IconButton>
            </IndividualTouchPointTimingSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const IndividualTouchPointTimingSC = styled.div`
    display : flex;
    flex-grow : 1;
    position : relative;

    .delete-touchpoint-button {
        position : absolute;
        bottom : 15px;
        right : 15px;
    }
    .content {
        flex-grow : 1;
        display : flex; 
    }

    >div {
        padding : 25px;
    }

`

export default IndividualTouchPointTiming