import React from 'react';
import styled from 'styled-components';
import { IndividualTrainingContext } from './IndividualTraining';
import SurveyMenu from './SurveyMenu';

const SureyOptionsButton = ({ training, divRef }) => {

    const { state, setState } = React.useContext(IndividualTrainingContext);

    const rendering = () => {
        return (
            <SureyOptionsButtonSC>
                {state.surveyMenu &&
                    <SurveyMenu
                        instance={state.currentInstance}
                        training={training}
                        state={state}
                        setState={setState}
                        divRef={divRef}
                    />
                }
                <button 
                    className='button' 
                    onClick={() => setState({...state, surveyMenu : true})}
                >Feedback survey</button>
            </SureyOptionsButtonSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SureyOptionsButtonSC = styled.div`
    position : relative; 
    padding : 10px;
    padding-left : 0;
    padding-right : 0;
    margin-right : 10px;

    .button {
        min-height : 42px;
        font-family : inherit;
        display : flex;
        justify-content : space-between;
        padding : 23px;   
        background-color : white;
        box-shadow : 0 0 10px 0 rgba(0,0,0,0.1);
        border-radius : 5px;
        box-sizing : border-box;
        border : 0;

        &:hover {
            background-color : #e5e5e5;
            cursor : pointer; 
        }
    }
`

export default SureyOptionsButton