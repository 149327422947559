import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { colors } from '../../variables/Colors';
import ShareIcon from '@mui/icons-material/Share';
const LeftPanel = ({ certificate }) => {

    function capitalizeFirstLetter(str) {
        if (!str) return str;  // Check for empty string
        return str.charAt(0).toUpperCase() + str.slice(1);
      }

    const rendering = () => {
        return (
            <LeftPanelSC>
                <p className='user-name'>{`${capitalizeFirstLetter(certificate.user.firstName)} ${capitalizeFirstLetter(certificate.user.lastName)}`}</p>
                <p className='date'>{moment(certificate.certificateDate).format('DD/MM/YYYY')}</p>
                <p className='description'>
                    {`On ${moment(certificate.certificateDate).format('DD/MM/YYYY')}, ${certificate.user.firstName} ${certificate.user.lastName} succesfully completed the course ${certificate.trainingName} at Credon.`}
                </p>
                <p style={{marginTop : '20px', marginBottom : '20px'}}>Share on</p>
                <button>
                    <img src='https://cdn.uconnectlabs.com/wp-content/uploads/sites/46/2022/08/Linkedin-Logo-e1660320077673.png' alt='linkedin logo' />
                </button>
                <button>
                    <img src='https://static.vecteezy.com/system/resources/thumbnails/018/910/719/small/facebook-logo-facebook-icon-free-free-vector.jpg' alt='linkedin logo' />
                </button>
            </LeftPanelSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const LeftPanelSC = styled.div`
    padding : 20px;
    max-width : 350px;

    .user-name {
        font-size : 1.5rem;
        font-weight : 700;
        margin-bottom : 10px;
    }

    .date {
        font-size : 0.8rem;
        font-weight : 400;
        color : ${colors.lightGrey}
    
    }

    .description {
        margin-top : 20px;
        font-size : 1.1rem;
        font-weight : 300;
        color : ${colors.midGrey}
    }

    button {
        display : flex;
        align-items : center;
        padding : 10px 30px;
        border : 0;
        border-radius : 5px;
        background-color : white;
        margin-top : 10px;
        max-height : 40px;
        box-shadow : 0 0 5px 0 rgba(0,0,0,0.1);
        &>img {
            max-height : 20px;
        }

        &:hover {
            cursor : pointer;
            background-color : #fafafa;
        }

        &>svg {
            max-height : 15px;
            margin-right : 10px;
            color : grey;
        }
}
`

export default LeftPanel