import React from 'react';
import styled from 'styled-components';
import Chart from 'react-google-charts';

const BarchartObject = () => {

    const data = [
        ["Yearmonth", "Revenue"],
        ["Jan 2024", 1000],
        ["Feb 2024", 1170],
        ["Mar 2024", 660],
        ["Apr 2024", 1030],
        ["Jun 2024", 1030],
        ["Jul 2024", 1000],
        ["Aug 2024", 1170],
        ["Sep 2024", 300]
    ];

    const options = {
        chart: {
          title: "Company Performance",
          subtitle: "Sales, Expenses, and Profit: 2014-2017",
        },
    };
      
    const rendering = () => {
        return (
            <BarchartObjectSC>
                <Chart
                    chartType="Bar"
                    width="745px"
                    height="300px"
                    data={data}
                    options={options}
                />
            </BarchartObjectSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const BarchartObjectSC = styled.div`
    padding : 15px;
    background-color : white;
    border-radius : 10px;
    margin : 15px;
    margin-top : 0;

`

export default BarchartObject