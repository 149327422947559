import React from 'react';
import styled from 'styled-components';

const TemplatesGrid = ({ templates }) => {

    const rendering = () => {
        return (
            <TemplatesGridSC>
                {templates.map((template) => {
                    console.log('template', template);
                    return (
                        <div key={template._id} className='template-item'>
                            <div className='background-image' style={{backgroundImage : `url(${template.imageUrl})`}}></div>
                            <div className='template-info'>
                                <p className='template-name'>{template.certificateName}</p>
                                <p className='template-text'>{template.certificateText}</p>
                            </div>
                        </div>
                    );
                })}
            </TemplatesGridSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TemplatesGridSC = styled.div`
    display : grid;
    grid-template-columns : repeat(auto-fill, minmax(180px, 1fr));
    grid-gap : 10px;
    padding : 10px;
    padding-left : 0;

    .template-item {
        display : flex;
        flex-direction : column;
        align-items : center;
        padding : 10px;
        border-radius : 10px;
        background-color : white;
        box-shadow : 0px 0px 10px 0px rgba(0,0,0,0.1);
        min-height : 180px;

        .background-image {
            min-width : 100%;
            aspect-ratio : 1/1;
            background-size : contain;
            background-position : center;
            border-radius : 10px;
            background-repeat : no-repeat;
        }

        .template-info {
            display : flex;
            flex-direction : column;
            padding : 25px;
            width : 100%;

            .template-name {
                font-size : 1.2em;
                font-weight : 600;
                padding-bottom : 0;

            }

            .template-text {
                font-size : 0.8em;
            }
        }
    }
`

export default TemplatesGrid