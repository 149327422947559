import React from 'react';
import styled from 'styled-components';
import DashboardObjects from './dashboardObjects/DashboardObjects';
import Axios from 'axios'; 
import { backEndAdress } from '../../variables/back-endAdress';

export const MetricDashboardContext = React.createContext(null); 

const MetricDashboard = () => {

    const [instances, setInstances] = React.useState([])
    const [trainings, setTrainings] = React.useState([])

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await Axios.get(`${backEndAdress}/api/trainings`);
                console.log('result : ', result);
                setTrainings(result.data);
            } catch (error) {
                console.log(error);
            }
        }

        fetchData();
    }, [])

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await Axios.get(`${backEndAdress}/api/instances`);
                setInstances(result.data);
            } catch (error) {
                console.log(error);
            }
        }

        fetchData();
    }, [])



    const rendering = () => {
        if(instances.length === 0 || trainings.length === 0) return (<div>Loading...</div>);
        return (
            <MetricDashboardContext.Provider 
                value={{
                    instances, 
                    trainings, 
                }}>
                <MetricDashboardSC>
                    <DashboardObjects />
                </MetricDashboardSC>
            </MetricDashboardContext.Provider>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const MetricDashboardSC = styled.div`
    display : flex;
`

export default MetricDashboard