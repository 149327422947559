import React from 'react';
import styled from 'styled-components';
import { IndividualTrainingContext } from './IndividualTraining';
import { useNavigate } from 'react-router-dom';

const SubscribeButton = () => {

    const { state, setState } = React.useContext(IndividualTrainingContext);
    const navigate = useNavigate();
    const navToSignUp = () => {
        navigate(`/sign-up/${state.currentInstance.trainingId}/${state.currentInstance._id}` , { replace : true });
    }

    const rendering = () => {
        return (
            <SubscribeButtonSC>
                <button className='button' onClick={() => navToSignUp()}>Sign up</button>
            </SubscribeButtonSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SubscribeButtonSC = styled.div`
    position : relative; 
    padding : 10px;
    padding-left : 0;

    .button {
        min-height : 42px;
        font-family : inherit;
        display : flex;
        justify-content : space-between;
        padding : 23px;   
        background-color : white;
        box-shadow : 0 0 10px 0 rgba(0,0,0,0.1);
        border-radius : 5px;
        box-sizing : border-box;
        border : 0;
        margin-right : 10px;
        margin-left : -10px;

        &:hover {
            background-color : #e5e5e5;
            cursor : pointer; 
        }
    }
`

export default SubscribeButton