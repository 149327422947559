import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { IndividualTrainingContext } from '../IndividualTraining';

const TopBar = () => {

    const { state ,setState, sendOutSurvey } = React.useContext(IndividualTrainingContext);

    const rendering = () => {
        return (
            <TopBarSC>
                <div className='top-wrapper'>
                    <p className='title-mail-schedule'>Mail schedule</p>
                    <div className='buttons-topper'>
                        <Button
                            variant='outlined'
                            onClick={sendOutSurvey}
                        >   
                            Send out
                        </Button>
                        <Button
                            variant='outlined'
                            onClick={() => setState({...state, sendOutSurveyModalToggled : false  })}
                        >   
                            close
                        </Button>
                    </div>
                </div>
            </TopBarSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TopBarSC = styled.div`
    .top-wrapper {
        display : flex;
        justify-content : space-between;

        .buttons-topper {
            background-color : white;
            display : flex;
            align-items : flex-end;
            justify-content : flex-end;
            height : 80px;
            overflow-y : auto;
            box-sizing : border-box;
            align-items : center;
            margin-right : 20px;

            &>:first-child {
                margin-right : 10px;
            }
        }

        .title-mail-schedule {
            display : flex;
            padding-left : 20px;
            justify-content : center;
            align-items : center;

        }
    }
            
`

export default TopBar