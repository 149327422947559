import React from 'react';
import styled from 'styled-components';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Modal from '../../../GeneralComponents/Modal';
import AvailableLocationsTable from './AvailableLocationsTable';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';

const AvailableLocations = ({ locations, state, setState, trainingToEditLocal, setTrainingToEditLocal }) => {

    const [locationsPopUp, setLocationsPopUp] = React.useState(false);



    const rendering = () => {
        return (
            <AvailableLocationsSC >
                <>
                    {locationsPopUp && 
                        <Modal>
                            <AvailableLocationsTable
                                locations={locations}
                                setLocationsPopUp={setLocationsPopUp}
                                state={state}
                                setState={setState}
                                trainingToEditLocal={trainingToEditLocal}
                                setTrainingToEditLocal={setTrainingToEditLocal}
                            />
                        </Modal>
                    }
                </>
                <div 
                    className='input-field-container'
                    onClick={() => setLocationsPopUp(true)}
                >
                    <p 
                        className='input-container-value'
                        style={{ color : 'black'}}    
                    >
                        {trainingToEditLocal.customProperties.availableLocations ? trainingToEditLocal.customProperties.availableLocations.length === 0 ? 'Available locations' : trainingToEditLocal.customProperties.availableLocations.map(loc => loc.title).join(', ') : 'Available locations'}
                    </p>
                    <ArrowDropDownIcon />
                    <p className='label-for-input'>Locations</p>

                </div>
            </AvailableLocationsSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const AvailableLocationsSC = styled.div`
    min-width : 100%;

    .input-field-container {
        min-width : 100%;
        border : 1px solid lightgrey;
        border-radius : 5px;
        height : 56px;
        padding : 15px;
        box-sizing : border-box;
        display : flex;
        justify-content : space-between;
        align-items : center;
        color: grey;
        font-family : 'Roboto', sans-serif;
        font-weight : 400;
        display : flex;
        position : relative;

        &:hover {
            cursor : pointer;
            border : 1px solid black;
        }

        &>svg {
            color : #757575;
            margin-right : -8px;
        }

        .input-container-value {
            font-family : 'Outfit', sans-serif;
        }
    }

    .label-for-input {
        position : absolute;
        top : -10px;
        font-size : 12px;
        left : 10px;
        color : grey;
        background-color : white;
        padding : 0 5px;
    }

`

export default AvailableLocations