import React from 'react';
import styled from 'styled-components';
import { MetricDashboardContext } from '../MetricDashboard';

const NumberOfAttendees = () => {

    const { instances } = React.useContext(MetricDashboardContext);

    const numberOfAttendees = instances.reduce((acc, instance) => {
        return acc + instance.attendees.length;
    }, 0);

    const rendering = () => {
        return (
            <NumberOfAttendeesSC>
                <p className='kpi-label'>Attendees</p>
                <p className='kpi-value'>{numberOfAttendees}</p>
            </NumberOfAttendeesSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const NumberOfAttendeesSC = styled.div`
    background-color : white;
    padding : 15px;
    border-radius : 10px;
    margin : 15px;
    display : flex;
    flex-direction : column;
    min-width : 180px;

    .kpi-label {
        font-size : 0.8rem;
        color : grey;
    }

    .kpi-value {
        font-size : 1.5rem;
    }
`

export default NumberOfAttendees