import React from 'react';
import styled from 'styled-components';
import { IndividualTrainingContext } from '../../IndividualTraining';

const InstanceLocation = () => {

    const { trainingLocation } = React.useContext(IndividualTrainingContext);

    const rendering = () => {
        return trainingLocation &&
             (
                <InstanceLocationSC>
                <div className='avatar' style={{ backgroundImage : `url(${trainingLocation.thumbnailImageUrl})`}}>
                </div>
                <div className='location-information'>
                    <p className='location-title title'>{trainingLocation.title}</p>
                    <p className='location-adress'>{trainingLocation.adress}</p>
                </div>
            </InstanceLocationSC>
            );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const InstanceLocationSC = styled.div`
    width : 100%;
    padding : 15px;
    box-sizing : border-box;
    background-color : white;
    border-radius : 5px;
    margin-top : 10px;
    box-shadow : 0px 0px 5px 0px rgba(0,0,0,0.1);
    display : flex;

    .avatar {
        min-height : 100px; 
        max-height : 100px;
        min-width : 100px;
        max-width : 100px;
        background-size : cover;
        background-position : center;
        border-radius : 5px;
    }

    .location-information {
        display : flex; 
        flex-direction : column;
        padding : 15px;
        padding-top : 0;
        padding-left : 20px;
    }

    .title {
        font-size : 1.2em;
    }

    .location-adress {
        color : grey;
    }
`

export default InstanceLocation