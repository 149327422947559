import React from 'react';
import styled from 'styled-components';
import Modal from '../../GeneralComponents/Modal';
import Attendee from './Attendee';
import TopBar from './TopBar';
import { IndividualTrainingContext } from '../IndividualTraining';

const SendOutSurveyModal = ({ instance,  }) => {

    const { state, setState } = React.useContext(IndividualTrainingContext);

    React.useEffect(() => {
        const idsOfAllAttendees = instance.attendees.filter(attendee => !attendee.receivedSurvey);
        setState({...state, selectedForSurvey : idsOfAllAttendees});
    },[]);

    const rendering = () => {
        return (
            <Modal>
                <SendOutSurveyModalSC>
                    <TopBar />
                    {instance.attendees.map((attendee) =>  <Attendee attendee={attendee} />)}
                </SendOutSurveyModalSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SendOutSurveyModalSC = styled.div`
    min-height : 80vh;
    min-width : 80vw;
    background-color : white;
    border-radius : 5px;
    display : flex; 
    flex-direction : column;
    box-sizing : border-box;
    position : relative;

    .title {
        font-size : 1.5rem;
        margin-top : 0;
        margin-bottom : 25px;
        padding : 25px;
    }
`

export default SendOutSurveyModal